import React from 'react';
import { isEmptyObject, isEmptyValue, isUndefined } from "../../../utils/JsObjectHelper";
import { Alert, Col, Form, Modal, Row, Select, Spin, } from 'antd';
import { withTranslation } from 'react-i18next'
import AttributesSectionDetail from '../edit/AttributesSectionDetail';
import { notificationSuccess } from '../../../utils/NotificationsHelper';
import { fetchBulkUpdateAttributsEntries } from '../../../apicalls/fetchBulkUpdateAttributsEntries';
import { fetchTranslate } from '../../../apicalls/locate/fetchTranslate';
const { Option } = Select;

class MultipleAttributeChangesModal extends React.Component {
    formRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            inProgress: false,
            loading:false
        };
    }
    onCancel = () => {
        this.props.cancel();
        this.setState({ inProgress: false });
    }
    // fillTranslations = async (localizations) => {
    //     let sourceText = localizations.en || Object.values(localizations).find(value => value);
    //     delete localizations.translate;
    //     for (const lang in localizations) {
    //         if (!localizations[lang]) {
    //             localizations[lang] = await fetchTranslate(lang, sourceText);
    //         }
    //     }
    //     return localizations;
    // }
    // translateLocStr = async (newValues) => {
    //     let entryType = this.props.entryTypesRequestResult.getData().find((el) => el.type === this.props.type[0]);
    //     let locStrs = entryType.properties.attributes.filter((el) => el.type === "localizedString");
    //     if (locStrs.length > 0) {
    //         for (const el of locStrs) {
    //             if (newValues[el.techName] && newValues[el.techName].translate) {
    //                 newValues[el.techName] = await this.fillTranslations(newValues[el.techName]);
    //             }
    //         }
    //     }
    //     return newValues;
    // }
    fillTranslations = async (localizations) => {
        let sourceText = localizations.en || Object.values(localizations).find(value => value);
        delete localizations.translate;
        for (const lang in localizations) {
            if (!localizations[lang]) {
                localizations[lang] = await fetchTranslate(lang, sourceText);
            }
        }
        return localizations;
    }
    translateLocStr = async (techName) => {
        this.setState({loading:true})
        let cloneProperties = this.formRef.current.getFieldsValue().properties;
        cloneProperties[techName] = await this.fillTranslations(cloneProperties[techName]);
        this.formRef.current.setFieldsValue({ properties: cloneProperties });
        this.setState({loading:false})
    }

    onOk = async () => {
        this.setState({ inProgress: true });
        const formValues = this.formRef.current.getFieldsValue().properties;
        let finalValues = Object.entries(formValues).reduce((acc, [key, value]) => {
            if (value !== undefined && typeof value !== 'object') {
                acc[key] = value;
            }
            if (typeof value === 'object' && (value.label !== undefined || value.url !== undefined)) {
                acc[key] = value;
            }
            if (typeof value === 'object' && "en" in value) {
                acc[key] = value;
            }
            return acc;
        }, {});
        // let newValues = await this.translateLocStr(finalValues);
        let object = {
            entryIds: this.props.entriesId,
            editData: {
                partialPatchedProperties: finalValues
            }
        }
        fetchBulkUpdateAttributsEntries(object, (data) => {
            notificationSuccess("Success");
            this.props.cancel();
            this.setState({ inProgress: false });
        });

    }
    handleSubmit = (value) => {
        console.log(value)
    }
    createColumn(content, colWidth, key) {
        return <Col key={key} xs={24} lg={colWidth} className="attSectionRowGutter">{content}</Col>;
    }
    createRow(content, key) {
        return <Row key={key} align="top" gutter={[{ xs: 0, sm: 8, lg: 16, xl: 32 }, 0]} wrap={true} /*type="flex"*/>{content}</Row>;
    }
    handleRelationChanged = () => {

    }

    render() {
        let layoutContent = [];
        const { t } = this.props;
        if (!isUndefined(this.props.entryTypesRequestResult) && this.props.entryTypesRequestResult.getState().isDone()) {

            let objDefData = this.props.entryTypesRequestResult.getData().find(oL => { return oL.type === this.props.type[0] });
            let sorted = objDefData.properties["editLayout"].layout.sort((a, b) => (a.y === b.y) ? (a.x - b.x) : (a.y - b.y));
            let uniqueRows = [...new Set(sorted.map(item => item.y))];
            let attributesWithoutRelation = objDefData.properties.attributes.map(item =>
                (item.type === 'relation' || item.type === "fileUrlArray") ? null : item
            );
            // let attributesWithoutRelation = attributesWithPlaceholders.filter(item => item !== null);
            uniqueRows.forEach(rowY => {
                let rowContent = [];
                sorted.forEach(col => {
                    if (col.y === rowY) {
                        let currentAttributeDef = objDefData.properties["editLayout"].attributesDivDefinition.find(a => a.index === parseInt(col.i));
                        if (!isEmptyObject(currentAttributeDef)) {
                            let colContent = <Spin spinning={this.state.loading}><AttributesSectionDetail
                                translateLocStr={this.translateLocStr}
                                isEditMode={true}
                                onRelationChange={this.handleRelationChanged}
                                attributeValues={{}}
                                attributeIndexes={currentAttributeDef.attributeIndexes}
                                attributeDefinitions={attributesWithoutRelation}
                                attributesProperties={objDefData.properties["editLayout"].attributesProperties}
                                sectionName={currentAttributeDef.name}
                                relationData={[]}
                                entryID={this.props.data}
                                showEditPrompt={false}
                                hasDraft={false}
                                formRef={this.formRef}
                                isLoadingRelations={undefined}
                                multipleEdit={true}
                            >
                            </AttributesSectionDetail></Spin> 

                            rowContent.push(this.createColumn(colContent, col.w, rowY + '-' + col.x));
                        }
                    }
                });

                layoutContent.push(this.createRow(rowContent, rowY));
            });
        }
        let forma = <Form
            onFinish={this.handleSubmit}
            ref={this.formRef}
            name="entry_edit_form"
            layout="vertical"
            style={
                { overflowY: "auto", height: "850px", overflowX: "hidden", }
            }
        ><Alert message={t('app.entry.view.multipleAttributeChangesAlert')} type="warning" />
            {layoutContent}
        </Form>
        return <>
            <Modal
                visible={this.props.visible}
                title={t('app.entry.view.modalMultiSelectionEditAtt')}
                onCancel={this.onCancel}
                onOk={this.onOk}
                width="60%"
            >
                <Spin spinning={this.state.inProgress} size="large">

                    {forma}
                </Spin>
            </Modal>

        </>;

    }
}

export default withTranslation()(MultipleAttributeChangesModal);

