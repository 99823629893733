import React, { Component } from 'react';
import { ZoomInOutlined, ZoomOutOutlined, SearchOutlined, PrinterOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import ReactHtmlParser from 'react-html-parser';
class DocumentViewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scale: 1,
      isDragging: false,
      lastMouseX: 0,
      lastMouseY: 0,
      offsetX: 0,
      offsetY: 0,
      mainHeight: 0,
      isZoomed: false,
    };

    this.contentRef = React.createRef();
  }
  componentDidMount() {
    this.updateScaleToFit();
    window.addEventListener('resize', this.updateScaleToFit);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateScaleToFit);
  }

  updateScaleToFit = () => {
    const screenWidth = window.innerWidth;
    let scale = 1;
    let offsetX = 0;
    const ranges = [
      { max: 1300, min: 1151, baseScale: 1, baseOffsetX: 0, stepScale: 0.01, stepOffsetX: 7 },
      { max: 1150, min: 1001, baseScale: 0.88, baseOffsetX: 71, stepScale: 0.01, stepOffsetX: 7 },
      { max: 1000, min: 851, baseScale: 0.76, baseOffsetX: 142, stepScale: 0.01, stepOffsetX: 7 },
      { max: 850, min: 701, baseScale: 0.63, baseOffsetX: 234, stepScale: 0.01, stepOffsetX: 7 },
      { max: 700, min: 551, baseScale: 0.53, baseOffsetX: 300, stepScale: 0.01, stepOffsetX: 7 },
      { max: 550, min: 450, baseScale: 0.41, baseOffsetX: 380, stepScale: 0.01, stepOffsetX: 7 },
      { max: 450, min: 350, baseScale: 0.33, baseOffsetX: 435, stepScale: 0.01, stepOffsetX: 7 },
      { max: 350, min: 0, baseScale: 0.25, baseOffsetX: 500, stepScale: 0.01, stepOffsetX: 7 },
    ];

    const activeRange = ranges.find(range => screenWidth <= range.max && screenWidth > range.min);

    if (activeRange) {
      const baseWidth = Math.floor(screenWidth / 10) * 10;
      const steps = (activeRange.max - baseWidth) / 10;
      scale = activeRange.baseScale - steps * activeRange.stepScale;
      offsetX = activeRange.baseOffsetX + steps * activeRange.stepOffsetX;
    }

    let doc = document.getElementById('DocumentLayoutMain') || document.getElementById('DocumentLayoutTemplateMain');
    let mainHeight = '0px';

    if (doc) {
      const computedStyle = window.getComputedStyle(doc);
      mainHeight = computedStyle.height;

      const heightAfterScale = parseFloat(mainHeight) * scale + 100 + 'px';

      this.setState({
        scale: scale,
        offsetX: offsetX,
        offsetY: 0,
        mainHeight: heightAfterScale,
        isZoomed: false,
      });
    }
  };



  zoomIn = () => {
    this.setState(state => {
      const newScale = state.scale + 0.1;
      const contentRect = this.contentRef.current.getBoundingClientRect();
      const containerRect = this.contentRef.current.parentNode.getBoundingClientRect();

      // Calculate the difference in content size after scaling up
      const diffWidth = contentRect.width * newScale - contentRect.width * state.scale;
      const diffHeight = contentRect.height * newScale - contentRect.height * state.scale;

      // Update the offset to keep the content centered
      return {
        scale: newScale,
        offsetX: state.offsetX - diffWidth / 2,
        offsetY: state.offsetY - diffHeight / 2,
        isZoomed: true,
      };
    });
  };


  zoomOut = () => {
    this.setState(state => {
      const newScale = state.scale - 0.1;
      const contentRect = this.contentRef.current.getBoundingClientRect();
      const containerRect = this.contentRef.current.parentNode.getBoundingClientRect();

      // Calculate the difference in content size after scaling down
      const diffWidth = contentRect.width * state.scale - contentRect.width * newScale;
      const diffHeight = contentRect.height * state.scale - contentRect.height * newScale;

      // Update the offset to keep the content centered
      return {
        scale: newScale,
        offsetX: state.offsetX + diffWidth / 2,
        offsetY: state.offsetY + diffHeight / 2
      };
    });
  };


  startDragging = (e) => {
    // Adding a style to prevent text selection
    // document.body.style.userSelect = 'none';
    e.preventDefault();
    this.setState({
      isDragging: true,
      lastMouseX: e.type.startsWith('touch') ? e.touches[0].clientX : e.clientX,
      lastMouseY: e.type.startsWith('touch') ? e.touches[0].clientY : e.clientY,
    });
  };

  stopDragging = (e) => {
    e.preventDefault();
    if (this.state.isDragging) {
      // Remove the style to allow text selection
      //document.body.style.userSelect = '';

      this.setState({
        isDragging: false
      });
    }
  };


  onDrag = (e) => {
    e.preventDefault();
    if (!this.state.isDragging || !this.state.isZoomed) return;

    let clientX, clientY;

    // Check the event type and the presence of touches for touch events
    if (e.type.startsWith('touch')) {
      if (e.touches && e.touches.length > 0) {
        clientX = e.touches[0].clientX;
        clientY = e.touches[0].clientY;
      } else {
        // If there are no touches, abort the function
        console.error('Touch event detected, but no touch information found');
        return;
      }
    } else {
      // For mouse events, use the standard clientX and clientY properties
      clientX = e.clientX;
      clientY = e.clientY;
    }

    const { lastMouseX, lastMouseY, offsetX, offsetY } = this.state;
    let dx = clientX - lastMouseX;
    let dy = clientY - lastMouseY;

    this.setState({
      offsetX: offsetX + dx,
      offsetY: offsetY + dy,
      lastMouseX: clientX,
      lastMouseY: clientY
    });
  };


  printDocument = () => {
    // Создаем новое окно
    window.print();
}





  render() {
    const { scale, offsetX, offsetY, mainHeight } = this.state;

    const transformStyle = {
      transform: `scale(${scale}) translate(${offsetX}px, ${offsetY}px)`,
      transformOrigin: 'top left',
    };
    return (

      <div style={{ position: 'relative' }}>
        <div
          style={{ position: 'relative', overflow: 'hidden', height: mainHeight }}
          onMouseDown={this.startDragging}
          onMouseMove={this.onDrag}
          onMouseUp={this.stopDragging}
          onMouseLeave={this.stopDragging}
          onTouchStart={this.startDragging} // Added for touch support
          onTouchMove={this.onDrag} // Added for touch support
          onTouchEnd={this.stopDragging} // Added for touch support
        >
          <div
            style={transformStyle}
            ref={this.contentRef}
          >
            {ReactHtmlParser(this.props.descriptionHtml)}
          </div>
        </div>
        <div className='documentViewerPanel' >
          <Button type='link' onClick={this.zoomIn}><ZoomInOutlined style={{ color: 'white', fontSize: '20px' }} /></Button>
          <Button type='link' onClick={this.zoomOut}><ZoomOutOutlined style={{ color: 'white', fontSize: '20px' }} /></Button>
          <Button type='link' onClick={this.updateScaleToFit}><SearchOutlined style={{ color: 'white', fontSize: '20px' }} /></Button>
          {/* <Button type='link' onClick={this.printDocument}><PrinterOutlined style={{ color: 'white', fontSize: '20px' }} /></Button> */}
        </div>

      </div>
    );
  }
}

export default DocumentViewer;
