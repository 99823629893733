import React from "react";
import {isEmptyObject} from "../../utils/JsObjectHelper";
import PropTypes from "prop-types";
import EntryEditForm from "./EntryEditForm";
import {entryResultDetailShape, incomingRelationsRequestResult, outgoingRelationsRequestResult} from "../../shapes/RequestResult";
import {relationsHolderShape} from "../../shapes/RelationShapes";
import { withTranslation} from 'react-i18next'
import EntryEditConcurrency from '../../containers/detail/EntryEditConcurrency'


class EntryEdit extends React.Component {

    componentDidMount() {
        if(!this.props.objectListRequestResult.data){
            this.props.onMount();
        }
        this.props.sendEditEvent(this.props.match.params.entryID);
        this.interval = setInterval(() => {
            this.props.sendEditEvent(this.props.match.params.entryID);
        }, 20000);
    }

    componentWillUnmount() {
        this.props.sendCancelEditEvent(this.props.match.params.entryID);
        clearInterval(this.interval);
    }

    render() {
        const {t} = this.props;
        /** @type {RequestResult} */
        const rr = this.props.entryRequestResult;
        /** @type {RequestResult} */
        const rrInc = this.props.incomingRelationsRequestResult;
        /** @type {RequestResult} */
        const rrOut = this.props.outgoingRelationsRequestResult;
        if(
            !(isEmptyObject(rr) || rr.getState().isLoading() || isEmptyObject(rr.getData())) &&
            !(isEmptyObject(rrInc) || rrInc.getState().isLoading()) &&
            !(isEmptyObject(rrOut) || rrOut.getState().isLoading())
        ) {
            /** @type {BaseEntryWithParent} */
            const entry = rr.getData();

            return <div>
                <h1>{t('app.entry.edit.editPageHeader')} {entry.name}</h1>
                <EntryEditForm
                    data={entry} 
                    onSaveEdit={this.props.onSaveEdit} 
                    incomingRelationsHolder={this.props.incomingRelationsHolder} 
                    outgoingRelationsHolder={this.props.outgoingRelationsHolder}
                    entryState={rr.getState()}
                    objectListRequestResult={this.props.objectListRequestResult}  />
                <EntryEditConcurrency></EntryEditConcurrency>
            </div>;
        } else {
            return <p>&nbsp;</p>;
        }
    }
}
export default withTranslation() (EntryEdit);

EntryEdit.propTypes = {
    entryRequestResult: entryResultDetailShape.isRequired,
    incomingRelationsRequestResult: incomingRelationsRequestResult.isRequired,
    outgoingRelationsRequestResult: outgoingRelationsRequestResult.isRequired,
    onSaveEdit: PropTypes.func.isRequired,
    sendEditEvent: PropTypes.func.isRequired,
    sendCancelEditEvent: PropTypes.func.isRequired,
    incomingRelationsHolder: relationsHolderShape,
    outgoingRelationsHolder: relationsHolderShape,
};

