import DecriptionLayoutPresenter from '../../components/object/descriptionLayout/DecriptionLayout';
import { connect } from 'react-redux';
import {withRouter} from "react-router-dom";
import { fetchAllBrandingActive } from '../../actions/branding/allBrandingActive';

const mapStateToProps = (state, ownProps) => {
 
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        refresh() {
            dispatch(fetchAllBrandingActive(true));
        },
    }
};

const DescriptionLayout = connect(
    mapStateToProps,
    mapDispatchToProps
)(DecriptionLayoutPresenter);

export default withRouter(DescriptionLayout);