import React from "react";
import {isEmptyObject, isEmptyValue, isUndefined} from "../../utils/JsObjectHelper";
import PropTypes, { string } from "prop-types";
import {entryTypesRequestResult} from "../../shapes/RequestResult";
import {idNameWithTypeShape} from "../../shapes/IdNameShapes";
import { Alert, Avatar, Button, Card, Input, Modal, Select, Form, Checkbox } from 'antd';
import { SaveOutlined, KeyOutlined, BuildOutlined } from '@ant-design/icons';
import { withTranslation} from 'react-i18next';
import debounce from 'lodash/debounce';
import {fetchEntries} from '../../apicalls/fetchEntries';
import { fetchEntryDetail } from '../../apicalls/fetchEntryDetail';

//const cloneDeep = require('lodash.clonedeep');

const Option = Select.Option;

const EMPTY_STATE = {
    submitting: false,
    selectedEntryType: null,
    fetchedFolders: [],
    fetching: false,
    loadedFolder: null,
    isLoadingDetail: false,
    canAction: false
};

class EntryNewModal extends React.Component {

    formRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = EMPTY_STATE;
        //this.onMount = this.onMount.bind(this);
        this.validateLoadedFolder = this.validateLoadedFolder.bind(this);
        this.onFolderSearch = this.onFolderSearch.bind(this);
        this.onFolderSearch = debounce(this.onFolderSearch, 800);
        this.onFolderFetched = this.onFolderFetched.bind(this);
    }

    /**
     * React lifecycle after Mount method
     */
    componentDidMount() {
        this.props.onMount();
    }

    /**
     * React lifecycle after update method
     * 
     * @param {*} prevProps 
     * @param {*} prevState 
     */
     componentDidUpdate(prevProps, prevState) {
        if (this.props.isVisible !== prevProps.isVisible && this.props.isVisible) {
            let allowedParentsQuery = "";

            if (!isEmptyValue(this.props.allowedParents)) {
                this.props.allowedParents.forEach(p => {
                    allowedParentsQuery += "id:" + p + " ";
                });
            } else {
                allowedParentsQuery = "id:" + this.props.parentDefault.id;
            }
            this.formRef.current.resetFields();
            this.setState(EMPTY_STATE);
            fetchEntries(allowedParentsQuery,"entryNewModal", data => this.onFolderFetched(data), null, ["folder"]);
        }
    }

    /**
     * Reaction to submit button click. Prepare data from form and calls onSaveNew method from props.
     * 
     * @param {*} e Button click event
     */
    handleSave = (e) => {
        this.formRef.current.validateFields()
        .then(values => {
            this.setState({submitting: true});
                let newEntry = {
                    name: null,
                    type: null,
                    description: null,
                    properties: {}
                };
                newEntry.name = values["name"];
                newEntry.type = values["type"];
                newEntry.loadDescriptionFromDefinition = values["loadDescriptionFromDefinition"];
                this.props.onSaveNew(values["parentId"], newEntry);
        })
        .catch(errorInfo => {
            console.log(errorInfo);
            return;
        });
    };

    /**
     * Fetch folders according to search value (folder name). Sets fetching state of this control.
     * 
     * @param {string} value entry name for search
     */
    onFolderSearch = (value) => {
        this.setState({fetching: true});
        fetchEntries(value,"entryNewModal", data => this.onFolderFetched(data), null, ["folder"]);
    };

    /**
     * Reaction to folder detail data load. Filters loaded folders.
     * 
     * @param {baseEntryShape} folderData 
     */
    onFolderFetched = (folderData) => {
        //filter out home and all deleted folders
        let filteredFolders = folderData.filter(
                f=> f.type !== "home" && 
                isUndefined(f.deleted)
            );
        this.setState({ fetchedFolders: filteredFolders, fetching: false });
    }

    /**
     * Reaction to parent folder selection. Loads folder detail data. Cause folder validation according to new entrytype.
     * 
     * @param {string} folderId 
     */
    onFolderSelected = (folderId) => {
        this.setState({ isLoadingDetail: true });

        fetchEntryDetail(folderId, data => this.validateLoadedFolder(data));
    };

    /**
     * Reaction to Entry Type selection. Cause folder validation according to new entrytype.
     * 
     * @param {string} type 
     */
    onTypeSelected = (type) => {
        const rr = this.props.entryTypesRequestResult;
        let entryTypeList = (!isUndefined(rr.getData()) && !isEmptyValue(rr.getData())) ? rr.getData() : [];
        let entryType = entryTypeList.length > 0 ? entryTypeList.find((el) => el.type === type) : null;
        console.log(entryType.description);
        if (entryType && entryType.description) {
            this.setState({ isTemplate: true });
        }
        else {
            this.setState({ isTemplate: false });
        }
        this.setState({selectedEntryType : type}, ()=>{this.validateLoadedFolder(this.state.loadedFolder)});
    };

    /**
     * Check selected folder data.
     * 1. Check user rights. Must have EDIT rights to be able to save entry to the folder.
     * 2. Check if selected folder supports selected entry type.
     * Sets loaded folder isRightOK and isTypeOK attributes. Sets control state according to the check.
     * 
     * @param {baseEntryShape} folderData 
     */
    validateLoadedFolder = (folderData) => {
        if (!isUndefined(folderData)) {
            let canActionThis = false;
            folderData.isRightOK = folderData.userEntryAccess === "EDIT";
            folderData.isTypeOK = false;
            
            if (!isUndefined(folderData.properties)) {
                if (folderData.properties.childObjectEntryTypes.indexOf(this.state.selectedEntryType)>-1) {
                    folderData.isTypeOK = true;
                }
            }
            
            canActionThis = folderData.isRightOK && folderData.isTypeOK;

            this.setState({ isLoadingDetail: false, loadedFolder: folderData, canAction : canActionThis });
        } else {
            this.setState({ isLoadingDetail: false, canAction : false });
        }
    };

    render() {
        const {t} = this.props;

        let infoBoxContent = null;
        let infoPanel = null;
        let options = null;
        let folderOptions = null;

        if (!isEmptyValue(this.props.infoBoxText)) {
            infoBoxContent = <Alert message={this.props.infoBoxText} type="info" showIcon />;
        }

        //Construct Entry Type options
        let entryTypeList = (!isUndefined(this.props.entryTypesRequestResult.getData()) && !isEmptyValue(this.props.entryTypesRequestResult.getData())) ? this.props.entryTypesRequestResult.getData() : [];
        if(!isEmptyValue(this.props.allowedEntryTypes)) {
            entryTypeList = entryTypeList.filter(eT=> { return this.props.allowedEntryTypes.indexOf(eT.type) > -1});
        }
        options = entryTypeList.map(d => <Option value={d.type} key={d.name}>{d.name}</Option>);

        //Folders options
        //let folderOptions = [];
        if (!isUndefined(this.state.fetchedFolders)) {
            folderOptions = this.state.fetchedFolders.map(f=> <Option value={f.id} key={f.id} disabled={f.isDisabled}>{f.name}</Option>)
        }

        //Info panel for selected destination Folder rights and allowed entry type
        if (!isUndefined(this.state.loadedFolder) || this.state.isLoadingDetail) {
            infoPanel = <Card loading={this.state.isLoadingDetail} style={{marginTop: '20px'}}>
                            <div style={{marginTop: '5px'}}><Avatar shape="square" icon={<KeyOutlined />} 
                                style={{ backgroundColor: !isUndefined(this.state.loadedFolder) ? (this.state.loadedFolder.isRightOK ? "green" : "red")  : "red" }} />&nbsp;
                                { !isUndefined(this.state.loadedFolder) ? (this.state.loadedFolder.isRightOK ? t('app.entry.tools.actions.modalManipulationRightOK') : t('app.entry.tools.actions.modalManipulationRightNOK'))  : ""}
                            </div>
                            <div style={{marginTop: '5px'}}><Avatar shape="square" icon={<BuildOutlined />} 
                                style={{ backgroundColor: !isUndefined(this.state.loadedFolder) ? (this.state.loadedFolder.isTypeOK ? "green" : "red")  : "red"  }} />&nbsp;
                                { !isUndefined(this.state.loadedFolder) ? (this.state.loadedFolder.isTypeOK ? t('app.entry.tools.actions.modalManipulationTypeOK') : t('app.entry.tools.actions.modalManipulationTypeNOK'))  : ""}
                            </div>
                        </Card>;
        }
        let checkbox = null;
        if (this.state.isTemplate) {
            checkbox = <Form.Item
                name="loadDescriptionFromDefinition"
                valuePropName="checked">
                <Checkbox >{t('app.entry.new.checkboxUseTemplate')}</Checkbox>
            </Form.Item>
        }
        return (
            <Modal
                title={t('app.entry.new.modalTitle')}
                visible={this.props.isVisible}
                //onOk={this.handleOk}
                onCancel={this.props.onCancel}
                width="450px"
                footer={[<Button key="back" onClick={this.props.onCancel}>
                            {t('app.entry.new.btnReturn')}
                        </Button>]}
                >
                    {infoBoxContent}
                    <Form /*onSubmit={this.handleSubmit}*/
                        ref={this.formRef}
                        name="entry_new_modal"
                        layout="vertical"
                    >
                        <Form.Item label={t('app.entry.new.lblType')} 
                            name="type"
                            rules={[{required: true, message: t('app.entry.new.msgFillType')}]}
                        >
                            <Select
                                showSearch
                                style={{width: 400}}
                                placeholder={t('app.entry.new.lblTypeHint')}
                                optionFilterProp="value"
                                filterOption={(input, option) =>
                                    option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                allowClear={true}
                                onSelect={this.onTypeSelected}
                            >
                                {options}
                            </Select>
                        </Form.Item>
                        <Form.Item label={t('app.entry.new.lblParentFolder')} 
                            name="parentId"
                            rules={[{required: true, message: t('app.entry.new.msgFillParentFolder')}]}
                        >
                            <Select 
                                showSearch={isEmptyValue(this.props.allowedParents)}
                                placeholder={t('app.entry.new.lblParentFolderHint')}
                                style={{ width: 400 }} 
                                onSelect={this.onFolderSelected}
                                //value={this.state.manipulationData.targetFolderId}
                                onSearch={this.onFolderSearch}
                                filterOption={false}
                                optionFilterProp="children"
                                //showSearch
                                loading={isEmptyObject(this.state.selectedEntryType)}
                                //notFoundContent={this.state.fetching ? <Spin size="small" /> : <Empty></Empty>}
                                >
                                    {folderOptions}
                            </Select>
                        </Form.Item>

                        {infoPanel}

                        <Form.Item label={t('app.entry.new.lblName')} 
                            name="name"
                            rules={[{ required: true, message: t('app.entry.new.msgFillName') }]}
                        >
                            <Input placeholder={(this.props.folderCreation ? t('app.entry.new.lblNameHintFolder') : t('app.entry.new.lblNameHintObject'))} style={{ width: '100%' }} allowClear={true} />
                        </Form.Item>
                        {checkbox}
                        <Form.Item shouldUpdate>
                            {() => (
                                <Button type="primary" onClick={this.handleSave} /*htmlType="submit"*/ style={{marginRight: '10px'}} icon={<SaveOutlined />}
                                    disabled={
                                        isUndefined(this.formRef.current) ||
                                        (!isUndefined(this.formRef.current) && 
                                            (
                                                !this.formRef.current.isFieldsTouched(['name','parentId','type']) ||
                                                !!this.formRef.current.getFieldsError().filter(({ errors }) => errors.length).length
                                            )
                                        ) ||
                                        !this.state.canAction
                                    } 
                                    loading={this.state.submitting}
                                    >{t('app.entry.new.btnCreate')}
                                </Button>
                            )}
                        </Form.Item>
                    </Form>
            </Modal>
        );
    }
}

export default withTranslation() (EntryNewModal);

EntryNewModal.propTypes = {
    //parentEntryRequestResult: entryResultDetailShape.isRequired,
    isVisible: PropTypes.bool.isRequired,
    onSaveNew: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onMount: PropTypes.func.isRequired,
    parentDefault: idNameWithTypeShape.isRequired,
    allowedParents: PropTypes.arrayOf(string),
    entryTypesRequestResult: entryTypesRequestResult.isRequired,
    allowedEntryTypes: PropTypes.arrayOf(string),
    infoBoxText: PropTypes.string
};