import BrandingPresenter from '../../components/setup/branding/Branding';
import { connect } from 'react-redux';
import {withRouter} from "react-router-dom";
import { fetchAllBrandingActive } from '../../actions/branding/allBrandingActive';


const mapStateToProps = (state, ownProps) => {
 
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        refresh() {
            dispatch(fetchAllBrandingActive(true));
        },
    }
};

const Branding = connect(
    mapStateToProps,
    mapDispatchToProps
)(BrandingPresenter);

export default withRouter(Branding);