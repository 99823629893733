import {fetchJson} from "../utils/SuperAgentFetch";
import {config} from "../config/Config";


/**
 * 
 * @param {String} entryId 
 * @param {Function<Array[Object]>} callback 
 */
export const fetchEntryFilesCount = (entryId, callback) => {
    let r = fetchJson(`${config.url.API_URL}/bmcEntries/custom/item/${entryId}/files/count`);
    r.then(json => {
        if(json.content) {
            callback(json.content);
        } else {
            callback(null);
        }
    });
};