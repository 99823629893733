import React from "react";
import PropTypes from "prop-types";
import { bmcEntryShape, folderPropShape } from "../../../shapes/BmcEntryShape";
import { entryTypesRequestResult } from "../../../shapes/RequestResult";
import { isEmptyValue, isUndefined } from "../../../utils/JsObjectHelper";
import EntryChildTable from './EntryChildTable';
import EntryChildHierarchy from '../../../containers/detail/EntryChildHierarchy';
import { withTranslation } from 'react-i18next'
import { Radio, Button } from "antd";
import { TableOutlined, ForkOutlined, ThunderboltOutlined,ThunderboltFilled} from '@ant-design/icons';
import EntryChildMultiSelection from "../../../containers/detail/EntryChildMultiSelection";


const HierarchyTitle = ({ translator, value, onChangeEvent, userEntryAccess }) => (
    <div>
        <span>{translator('app.entry.view.tblEntriesTitle')}</span>
        {userEntryAccess && userEntryAccess === 'EDIT' ? <Button style={{ float: "right", bottom: "3px", borderLeft: "solid #efebeb 1px" }} type="link" onClick={() => onChangeEvent(value ==="multiSelect"?"table":"multiSelect")}>
       { value ==="multiSelect"?<ThunderboltFilled style={{ color: 'black',fontSize:'18px' }} />: <ThunderboltOutlined style={{ color: 'black',fontSize:'18px' }} />}
        </Button> : null}
        <Radio.Group onChange={onChangeEvent} value={value} style={{ float: "right" }}>
            <Radio value="table"><TableOutlined title={translator('app.entry.edit.lblChildrensDisplayTypeTable')} /></Radio>
            <Radio value="hierarchy"><ForkOutlined title={translator('app.entry.edit.lblChildrensDisplayTypeHierarchy')} /></Radio>
        </Radio.Group>
    </div>
);

const TableTitle = ({ translator, value, onChangeEvent, userEntryAccess }) => (
    <div className="childTableTitle">
        <span>{translator('app.entry.view.tblEntriesTitle')}</span>
        {userEntryAccess && userEntryAccess === 'EDIT' ? <Button style={{ float: "right", bottom: "3px", borderLeft: "solid #efebeb 1px" }} type="link" onClick={() => onChangeEvent(value ==="multiSelect"?"table":"multiSelect")}>
        { value ==="multiSelect"?<ThunderboltFilled style={{ color: 'black',fontSize:'18px' }} />: <ThunderboltOutlined style={{ color: 'black',fontSize:'18px' }} />}
        </Button> : null}
        <Radio.Group onChange={onChangeEvent} value={value} style={{ float: "right" }}>
            <Radio value="table"><TableOutlined title={translator('app.entry.edit.lblChildrensDisplayTypeTable')} /></Radio>
            <Radio value="hierarchy"><ForkOutlined title={translator('app.entry.edit.lblChildrensDisplayTypeHierarchy')} /></Radio>
        </Radio.Group>
    </div>
);
const TableMultiSelection = ({ translator, value, onChangeEvent, userEntryAccess,defaultType }) => (
    <div className="childTableTitle">
        <span>{translator('app.entry.view.tblEntriesMultiSelection')}</span>
        {userEntryAccess && userEntryAccess === 'EDIT' ? <Button style={{ float: "right", bottom: "3px", borderLeft: "solid #efebeb 1px" }} type="link" onClick={() => onChangeEvent(value ==="multiSelect"?defaultType:"multiSelect")}>
        { value ==="multiSelect"?<ThunderboltFilled style={{ color: 'black',fontSize:'18px' }} />: <ThunderboltOutlined style={{ color: 'black',fontSize:'18px' }} />}
        </Button> : null}
        <Radio.Group onChange={onChangeEvent} value={value} style={{ float: "right" }}>
            <Radio value="table"><TableOutlined title={translator('app.entry.edit.lblChildrensDisplayTypeTable')} /></Radio>
            <Radio value="hierarchy"><ForkOutlined title={translator('app.entry.edit.lblChildrensDisplayTypeHierarchy')} /></Radio>
        </Radio.Group>

    </div>
);

class EntryChildSection extends React.Component {

    state = {
        typeValue: (!isUndefined(this.props.folderProperties) && !isEmptyValue(this.props.folderProperties.childrenDisplayType) ? this.props.folderProperties.childrenDisplayType : "table"),
        defaultType: (!isUndefined(this.props.folderProperties) && !isEmptyValue(this.props.folderProperties.childrenDisplayType) ? this.props.folderProperties.childrenDisplayType : "table"),
    }

    
    componentDidUpdate(prevProps, prevState) {
        if(!isUndefined(this.props.folderProperties) && !isEmptyValue(this.props.folderProperties.childrenDisplayType) && this.props.folderProperties.childrenDisplayType !== this.state.typeValue && prevProps.folderProperties.childrenDisplayType!==this.props.folderProperties.childrenDisplayType){
            this.setState({ typeValue: this.props.folderProperties.childrenDisplayType});
        }
        }
    handleTypeChange = (e) => {
        let value = e.target ? e.target.value : e;
        this.setState({ typeValue: value });
    };

    render() {
        const { t } = this.props;

        switch (this.state.typeValue) {
            case "hierarchy":
                return <EntryChildHierarchy
                    childsDataList={this.props.childsDataList}
                    folderProperties={this.props.folderProperties}
                    entryTypesRequestResult={this.props.entryTypesRequestResult}
                    cardTitle={<HierarchyTitle translator={t} value={this.state.typeValue} onChangeEvent={this.handleTypeChange} userEntryAccess={this.props.entry.userEntryAccess} ></HierarchyTitle>}
                ></EntryChildHierarchy>;
            case "multiSelect":
                return <EntryChildMultiSelection
                    childsDataList={this.props.childsDataList}
                    folderProperties={this.props.folderProperties}
                    entryTypesRequestResult={this.props.entryTypesRequestResult}
                    tableTitle={<TableMultiSelection translator={t} defaultType={this.state.defaultType} value={this.state.typeValue} onChangeEvent={this.handleTypeChange} userEntryAccess={this.props.entry.userEntryAccess}></TableMultiSelection>}
                    handleTypeChange={this.handleTypeChange}
                ></EntryChildMultiSelection>;
            case "table":
            default:
                return <EntryChildTable
                    childsDataList={this.props.childsDataList}
                    folderProperties={this.props.folderProperties}
                    entryTypesRequestResult={this.props.entryTypesRequestResult}
                    tableTitle={<TableTitle translator={t} value={this.state.typeValue} onChangeEvent={this.handleTypeChange} userEntryAccess={this.props.entry.userEntryAccess}></TableTitle>}
                ></EntryChildTable>;



        }
    }
}

export default withTranslation()(EntryChildSection);

EntryChildSection.propTypes = {
    childsDataList: PropTypes.arrayOf(bmcEntryShape).isRequired,
    folderProperties: folderPropShape.isRequired,
    entryTypesRequestResult: entryTypesRequestResult.isRequired
};