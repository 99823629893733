export const EMPTY_ENTRYTYPE_ATTRIBUTE = {
    name: "", 
    techName: "", 
    type: "string", 
    maxLength: null, 
    minLength: null, 
    displayFormat: null, 
    relationEntryType: [], 
    relationEntryParent: [],
    selectedLanguages: ["en"],
    lovArray: [],
    isRequired: false, 
    deleted: false,
    isSearchable: false
};