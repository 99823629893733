import React from 'react';
import './App.css';
import 'antd/dist/antd.css';
//TODO: OLD FORM Hotfix - Po prehozeni Formu na nove zrusit
import '@ant-design/compatible/assets/index.css';

import SearchBox from "./containers/search/SearchBox";
import AppHeader from "./containers/AppHeader";
import { Helmet } from "react-helmet";
import { Route } from "react-router-dom";
import EntryDetail from "./containers/EntryDetail";
import EntryArchiveCompare from "./containers/detail/EntryArchiveCompare";
import Setup from "./containers/Setup";
import { Layout, ConfigProvider } from "antd";
import Notification from "./containers/Notification";
import PropTypes from "prop-types";
import { loggedUserShape } from "./shapes/LoggedUserShapes";
import Homepage from "./containers/Homepage";
import Lineage from './containers/lineage/Lineage';

import { withTranslation } from 'react-i18next';
import enUS from 'antd/es/locale/en_US';
import csCZ from 'antd/es/locale/cs_CZ';
import nlNL from 'antd/es/locale/nl_NL';
import FooterContent from './components/FooterContent';
import TaskList from './containers/bpmn/TaskList';
import UserPage from './containers/detail/UserPage';
import PluginPage from './containers/pluginPage/PluginPage';


// import TestLayout from "./components/TestLayout"

const { Header, Content, Footer/*, Sider*/ } = Layout;



class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            collapsed: false,
            locale: this.mapI18NLocale2AntDesign(props.i18n.language)
        };

        let that = this;
        props.i18n.on('languageChanged', function (lng) {
            that.handleLanguageChange(props.i18n.language);
        });

        this.onCollapse = this.onCollapse.bind(this);
        this.toggle = this.toggle.bind(this);
        this.onLogout = this.onLogout.bind(this);
    }

    componentDidMount = () => {
        this.props.onMount();
    }
    mapI18NLocale2AntDesign = (localeName) => {
        switch (localeName) {
            case "en":
                return enUS;
            case "cs":
                return csCZ;
            case "nl":
                return nlNL;
            default:
                return enUS;
        }
    };

    handleLanguageChange = (localeName) => {
        this.setState({ locale: this.mapI18NLocale2AntDesign(localeName) });
    };

    onCollapse = (collapsed) => {
        this.setState({ collapsed });
    };
    toggle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };

    onLogout = () => {
        this.props.onLogout(this.props.keycloak);
    };

    render() {
        if (this.props.token == null) return <div />;
        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Aphinit</title>
                </Helmet>
                <Layout style={{ minHeight: '100vh' }}>

                    <ConfigProvider locale={this.state.locale}>
                        {/* <Sider
                      collapsible
                      collapsed={this.state.collapsed}
                      onCollapse={this.onCollapse}>
                      <div className="logo" />
                      <AppMenu />
                  </Sider> */}
                        <Layout>
                            <Header style={{ background: '#fff', padding: 0, paddingLeft: 16, position: 'fixed', zIndex: 15, width: '100%' }}>
                                {/* <Icon
                                className="trigger"
                                type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
                                style={{ cursor: 'pointer' }}
                                onClick={this.toggle}
                            /> */}
                                <AppHeader onLogout={this.onLogout} loggedUser={this.props.loggedUser} isAdmin={this.props.isAdmin} hasSetupRight={this.props.hasSetupRight} />
                            </Header>
                            {/* style={{ margin: '100px 60px 0px 60px', padding: 24, background: '#fff', minHeight: 280 }} */}
                            <Content className='mainContent' >
                                {/*<h1>BMC app</h1>*/}
                                {/*<Route path="/" exact render={(props) => <SearchBox {...props} notificationSystem={this._notificationRef} />}  />*/}
                                <Route path="/" exact component={Homepage} />
                                {/* <Route path="/search" exact render={(props) => <SearchBox {...props} notificationSystem={this._notificationRef} />}  /> */}
                                {/*<Route path="/entry/:entryID([A-Za-z0-9_-]{1,255})/:edit(edit)?" render={(props) => <EntryDetail {...props} />} />*/}
                                <Route path="/search/:requestID([A-Za-z0-9_-]{1,255})?" component={SearchBox} />
                                <Route path="/entry/:entryID([A-Za-z0-9_-]{1,255})/:edit(edit|createNew|createNewFolder)?" component={EntryDetail} />
                                <Route path="/setup/:setuptype(users|idmapping|idmappingedit|groups|domain|localization|integration|content|contentedit|lookandfeel|lineage|lineageedit|reindex|infobpmn|workflow|tasklist|bpmnprocess|workflowedit|general|loader|loaderedit|loaderAudit|id2entrymapping|backup)?/:setupDetailId?" component={Setup} />
                                {/* <Route path="/testlayout/:entryTypeID([A-Za-z0-9_-]{1,255})"  component={TestLayout} /> */}
                                <Route path="/entryarchive/:entryID([A-Za-z0-9_-]{1,255})/:versionIdList?" component={EntryArchiveCompare} />
                                <Route path="/lineage/:entryID([A-Za-z0-9_-]{1,255})" component={Lineage} />
                                <Route path="/bpmn/:taskID([A-Za-z0-9_-]{1,255})?" component={TaskList} />
                                <Route path="/id2entry/:identityID([A-Za-z0-9_-]{1,255})" component={UserPage} />
                                <Route path="/userdashboard" component={PluginPage} />
                            </Content>
                            <Footer style={{ textAlign: 'center' }}>
                                <FooterContent></FooterContent>
                            </Footer>
                            <Notification />
                        </Layout>

                    </ConfigProvider>
                </Layout>
            </div>
        );
    }
}

export default withTranslation()(App);

App.propTypes = {
    keycloak: PropTypes.any.isRequired,
    onLogout: PropTypes.func.isRequired,
    loggedUser: loggedUserShape,
    isAdmin: PropTypes.bool.isRequired,
    hasSetupRight: PropTypes.bool.isRequired
};