import React from 'react';
import PropTypes from "prop-types";
import { isEmptyObject } from "../../../utils/JsObjectHelper";
import { Checkbox, Input, Select, Form } from 'antd';

import { withTranslation } from 'react-i18next'
import { entryTypesRequestResult } from '../../../shapes/RequestResult';

const { Option } = Select;

class LineageDetailForm extends React.Component {

    onFieldsChange = (changedFields, fields) => {
        this.props.onAttributeChange(changedFields);
    };
    checkLineageName = (rule, value) => {
        const { lineageSettingsList,activeId } = this.props;
        const existingName = lineageSettingsList.some(setting => setting.value.lineageName.trim() === value.trim() && activeId !== setting.name);
        if (existingName) {
            this.props.changeButtonState(true);
            return Promise.reject(new Error(this.props.t('setup.lineageSetup.detailFormNameExistsErr')));
        }
        this.props.changeButtonState(false);
        return Promise.resolve();
    };
    render() {
        const { t } = this.props;

        let entryTypeOptions = [];

        if (this.props.objectListRequestResult.getState().isDone() && !isEmptyObject(this.props.objectListRequestResult.getData())) {
            entryTypeOptions = this.props.objectListRequestResult.getData()
                .filter(et => { return (et.systemType === "object"); })
                .sort((a, b) => a.name.localeCompare(b.name))
                .map(et => <Option value={et.type} key={et.type}>{et.name}</Option>);
        }

        let formFields = [];
        Object.keys(this.props.lineageValueData).forEach((field) => {
            formFields.push({ name: [field], value: this.props.lineageValueData[field] });
        });
        return <Form name="lineage_form" layout="vertical"
            onFieldsChange={this.onFieldsChange}
            ref={this.props.extFormRef}
            fields={formFields}>
            <Form.Item label={t('setup.lineageSetup.detailFormName')}
                name="lineageName"
                rules={[{
                    required: true,
                    message: t('setup.lineageSetup.detailFormNameErr'),
                },
                {
                    validator: this.checkLineageName
                }]}>
                <Input placeholder={t('setup.lineageSetup.detailFormNameHint')} />
            </Form.Item>
            <Form.Item label={t('setup.lineageSetup.detailFormTypes')}
                name="entryTypes">
                <Select
                    mode="tags"
                    placeholder={t('setup.lineageSetup.detailFormTypesHint')}
                    filterOption={
                        (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {entryTypeOptions}
                </Select>
            </Form.Item>
            <Form.Item label={t('setup.lineageSetup.detailFormIsDisplayed')}
                name="isDisplayed"
                valuePropName="checked">
                <Checkbox ></Checkbox>
            </Form.Item>
        </Form>;
    }
}

export default withTranslation()(LineageDetailForm);

LineageDetailForm.propTypes = {
    extFormRef: PropTypes.any.isRequired,
    lineageValueData: PropTypes.object.isRequired,
    objectListRequestResult: entryTypesRequestResult.isRequired,
    onAttributeChange: PropTypes.func.isRequired
};