import React, { Component } from 'react';
import { SisternodeOutlined, PlusCircleOutlined, NodeIndexOutlined } from '@ant-design/icons';
import {  Button, Popover, Space, Empty, Divider } from "antd";
import { isEmptyObject, isUndefined } from '../../../utils/JsObjectHelper';
class CustomButtonsForDescription extends Component {
    constructor(props) {
        super(props);
        this.state = {
            popoverAtt: false,
            popoverRel: false,
            popoverRelIncoming: false,
        };


    }

    getRelationName = (sourceType, attType) => {
        let entryDef = this.props.objectListRequestResult.getData().find(eT => eT.type === sourceType);
        if (!isEmptyObject(entryDef) && !isUndefined(entryDef.properties.attributes)) {
            let attDef = entryDef.properties.attributes.find(att => att.techName === attType);
            return (!isEmptyObject(attDef) && !isEmptyObject(attDef.name)) ? attDef.name : attType;
        }
        return attType;
    };
    popoverChange = (value) => {
        this.setState({ popoverAtt: value, popoverRel: false,popoverRelIncoming: false, });
    };

    popoverRelChange = (value) => {
        this.setState({ popoverRel: value, popoverAtt: false,popoverRelIncoming: false,});
    };
    popoverRelIncomingChange = (value) => {
        this.setState({ popoverRelIncoming: value, popoverAtt: false, popoverRel: false });
    }
    handleSelectChange = (value)=>{
        this.setState({ popoverRelIncoming: false, popoverAtt: false, popoverRel: false });
        this.props.handleSelectChange(value);
    }

    render() {
        const { t, entryType, buttonRef,triger } = this.props;
        let buttonList = [];
        let myButtons = null;
        let relationsButtonList = [];
        let incomingRelationsBtn = [];
        entryType.properties.attributes.forEach((el, i) => {
            if (el.type === "hyperlink") {
                buttonList.push(<Button type="link" key={el.techName + i} style={{ color: 'black' }} onClick={() => this.handleSelectChange(`{{[${el.techName}|link]}}`)}>{el.name}</Button>);
            }
            if (el.type === "date") {
                buttonList.push(<Button type="link" key={el.techName + i} style={{ color: 'black' }} onClick={() => this.handleSelectChange(`{{[${el.techName}|date]}}`)}>{el.name}</Button>);
            }
            if (el.type !== "boolean" && el.type !== "relation" && el.type !== "hyperlink" && el.type !== "fileUrlArray" && el.type !== "date") {
                buttonList.push(<Button type="link" key={el.techName + i} style={{ color: 'black' }} onClick={() => this.handleSelectChange(`{{[${el.techName}]}}`)}>{el.name}</Button>);
            }
            if (el.type === 'relation') {
                let relationStyleList = <Space direction="vertical" size="small">
                    <Button type="link" key={el.techName + i + 'text'} style={{ color: 'black' }} onClick={() => this.handleSelectChange(`{{(${el.techName}|text)}}`)}>{t('app.entry.edit.insertAttRelationText')}</Button>
                    <Button type="link" key={el.techName + i + 'tag'} style={{ color: 'black' }} onClick={() => this.handleSelectChange(`{{(${el.techName}|tag)}}`)}>{t('app.entry.edit.insertAttRelationTag')}</Button>
                    <Button type="link" key={el.techName + i + 'table'} style={{ color: 'black' }} onClick={() => this.handleSelectChange(`{{(${el.techName}|table)}}`)}>{t('app.entry.edit.insertAttRelationTable')}</Button>
                    <Button type="link" key={el.techName + i + 'ul'} style={{ color: 'black' }} onClick={() => this.handleSelectChange(`{{(${el.techName}|ul)}}`)}>{t('app.entry.edit.insertAttRelationUl')}</Button>
                </Space>;
                relationsButtonList.push(<Popover
                    content={relationStyleList}
                    placement="right"
                    key={el.name + i}
                    trigger="hover"
                    title={t('app.entry.edit.insertAttRelation')}
                    zIndex="2147483656" >
                    {el.name}
                </Popover>);
            }
        });
        if (this.props.incomingRelationsHolder && this.props.incomingRelationsHolder.relations && this.props.incomingRelationsHolder.relations.length > 0) {
            this.props.incomingRelationsHolder.relations.forEach((element) => {
                let relationEntryType = this.props.objectListRequestResult.getData().find((el) => el.type == element.relatedEntries[0].type);
                let nameRel = this.getRelationName(relationEntryType.type, element.name)
                let relationStyleList = <Space direction="vertical" size="small">
                    <Button type="link" style={{ color: 'black' }} onClick={() => this.handleSelectChange(`{{{${element.name}|text}}}`)}>{t('app.entry.edit.insertAttRelationText')}</Button>
                    <Button type="link" style={{ color: 'black' }} onClick={() => this.handleSelectChange(`{{{${element.name}|tag}}}`)}>{t('app.entry.edit.insertAttRelationTag')}</Button>
                    <Button type="link" style={{ color: 'black' }} onClick={() => this.handleSelectChange(`{{{${element.name}|table}}}`)}>{t('app.entry.edit.insertAttRelationTable')}</Button>
                    <Button type="link" style={{ color: 'black' }} onClick={() => this.handleSelectChange(`{{{${element.name}|ul}}}`)}>{t('app.entry.edit.insertAttRelationUl')}</Button>
                </Space>;
                incomingRelationsBtn.push(<Popover
                    content={relationStyleList}
                    placement="right"
                    trigger="hover"
                    title={t('app.entry.edit.insertAttRelation')}
                    zIndex="2147483656">
                    {nameRel}{`  (${relationEntryType.name})`}
                </Popover>);
            })
        }
        myButtons = <div style={{ marginTop: '15px', marginBottom: '0px', display: 'none' }} ref={buttonRef} className='mybuttons' >
            <Space direction="horizontal" size="middle">
                <span title={t('app.entry.edit.insertAttTitle')}>
                    <Popover
                        visible={this.state.popoverAtt}
                        content={<Space direction="vertical" size="small">{buttonList}</Space>}
                        key="popoverButton"
                        placement="bottom"
                        trigger={triger}
                        onVisibleChange={this.popoverChange}
                        zIndex="2147483638">
                        <PlusCircleOutlined style={{ fontSize: '18px' }} />
                    </Popover>
                </span>
                <span title={t('app.entry.edit.insertAttRelOutTitle')}>
                    <Popover
                        visible={this.state.popoverRel}
                        content={<Space direction="vertical" size="small">{relationsButtonList}</Space>}
                        key="popoverButtonRel"
                        placement="bottom"
                        trigger={triger}
                        zIndex='2147483638'
                        onVisibleChange={this.popoverRelChange}>
                        <SisternodeOutlined style={{ fontSize: '18px', }} />
                    </Popover></span>
                <span title={t('app.entry.edit.insertAttRelIncTitle')}>
                    <Popover
                        visible={this.state.popoverRelIncoming}
                        content={incomingRelationsBtn.length > 0 ? <Space direction="vertical" size="small">{incomingRelationsBtn}</Space> : <Empty />}
                        placement="bottom"
                        trigger={triger}
                        zIndex="2147483638"
                        onVisibleChange={this.popoverRelIncomingChange}>
                        <NodeIndexOutlined style={{ fontSize: '18px', }} />

                    </Popover></span>
            </Space>
        </div>
        return myButtons
    }
}

export default CustomButtonsForDescription;
