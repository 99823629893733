import React from "react";
import { Link } from "react-router-dom";
import { isArray, isEmptyValue, isUndefined } from "../../../../utils/JsObjectHelper";
import { withTranslation } from 'react-i18next'
import { Alert, Button, } from "antd";
import { fetchEntryRelOutgoingLineage } from '../../../../apicalls/fetchEntryLineage'
import EntryTypeTag from "../../../controls/EntryTypeTag";
import { TableOutlined, } from "@ant-design/icons";
import Tree from "./Tree";
import SearchTree from "./SerchTree";
import { fetchRootHierarchy } from "../../../../apicalls/fetchRootHierarchy";
const cloneDeep = require('lodash.clonedeep');

const TOO_MUCH_RECORDS_IN_SUBTREE = "@#CANNOTBEAR#@";

class OutsideTheHierarchy extends React.Component {
    state = {
        treeData: [],
        fetchedData: {},
        selectedParent: null,
        hierarchyDefinition: null,
        loading: false,
        freeEntries: [],
    };

    componentDidMount() {
        this.setState({ treeData: this.props.treeData })
    }

    componentDidUpdate(prevProps, prevState) {
        //Check if hierarchy definition is laoded and selected
        if (isEmptyValue(this.state.hierarchyDefinition)) {
            this.findHierarchyDefinition();
        }
    };

    handleSizeChange = (tgt) => {
        this.setState({ panelSize: tgt.target.value });
    };

    /**
     * Try to find hierarchy definition selected for folder.
     */
    findHierarchyDefinition = () => {
        if (!isEmptyValue(this.props.lineageSettingsList) &&
            !isEmptyValue(this.props.folderProperties) &&
            !isEmptyValue(this.props.folderProperties.childrenDisplayHierarchyDefId)) {
            let hierarchyDef = this.props.lineageSettingsList.find(hD => hD.name === this.props.folderProperties.childrenDisplayHierarchyDefId);
            if (!isEmptyValue(hierarchyDef) && !isEmptyValue(hierarchyDef.value.hierarchy)) {
                this.setState({ hierarchyDefinition: hierarchyDef.value.hierarchy }, this.prepareHierarchyRoot);
            }
        }
    };

    /**
     * Sets default hierarchy root (root layer for Tree) according to hierarchy definition.
     */
    getRootEntries = (rootEntriesArr, oldArr) => {
        let rootEntries = rootEntriesArr.map(e => { return { id: e.id, title: e.name, key: e.id, type: e.type }; });
        /**
        * Get not hierarchical
         */
        let etNameList = [];
        this.state.hierarchyDefinition.forEach((h) => etNameList.push(h.parent, h.child));
        let arr = [...new Set(etNameList)];
        arr = arr.filter(item => !oldArr.includes(item));
        fetchRootHierarchy(this.props.entryID, arr.join(','), (free) => {
            if (isArray(free)) {
                let freeEntries = free.map(e => { return { id: e.id, title: e.name, key: e.id, type: e.type }; });
                this.setState({ freeEntries: freeEntries });
            }
        });
    }
    prepareHierarchyRoot = () => {
        let parentNameList = [];
        //find ultimate parents in hierarchy
        parentNameList = this.state.hierarchyDefinition.map(h => h.parent);

        this.state.hierarchyDefinition.forEach(d => {
            parentNameList = parentNameList.filter(pN => (pN !== d.child || d.parent === d.child));
        });

        let arr = [...new Set(parentNameList)];
        fetchRootHierarchy(this.props.entryID, arr.join(','), (root) => this.getRootEntries(root, arr));
    };
    /**
     * Handles TreeNodes request to load leaf data for node.
     * 
     * @param {*} treeNodeData 
     * @returns 
     */
    onLoadData = treeNodeData =>
        new Promise(resolve => {
            this.setState({ loading: true })
            if (treeNodeData.id === TOO_MUCH_RECORDS_IN_SUBTREE) {
                this.setState({ loading: false })
                resolve();
                return;
            }
            if (!(treeNodeData.key in this.state.fetchedData)) {
                fetchEntryRelOutgoingLineage(treeNodeData.id, (data) => { this.onRelationsLoaded(data, treeNodeData.key); resolve(); this.setState({ loading: false }) });
            } else {
                if (treeNodeData.children) {
                    this.setState({ loading: false })
                    resolve();
                    return;
                }
                this.setState({ loading: false })
                resolve();
            }
            return;
        });

    /**
     * Handle relations loaded. When there are some new relations loaded, ad them into Tree hierarchy data object.
     * 
     * @param {*} data 
     * @param {*} parentId 
     */
    onRelationsLoaded = (data, parentId) => {
        if (!isUndefined(data)) {
            let entryId = this.getItemIdFromIDPath(parentId);
            let parentType = this.props.childsDataList.find(e => e.id === entryId).type;
            let childsTreeArr = [];
            let childsArr = [];
            //Items can be displayed in hierarchy
            data.forEach(d => {
                console.log(d);
                if (isEmptyValue(d.deleted)) {
                    if (!isUndefined(this.state.hierarchyDefinition.find(h => h.parent === parentType && h.child === d.target.type && h.relation === d.name))) {
                        childsArr.push(d.target.id);
                        if (this.props.childsDataList.find(e => e.id === d.target.id)) {
                            childsTreeArr.push({ id: d.target.id, title: d.target.name, key: d.target.id, type: d.target.type, relType: d.name });
                        }
                    }
                }
            });
            let newData = cloneDeep(this.state.fetchedData);
            newData[parentId] = childsArr;

            let newTreeData = cloneDeep(this.state.treeData);
            console.log(newTreeData);
            childsTreeArr = childsTreeArr.sort((a, b) => a.title.localeCompare(b.title));
            this.insertTreeChildren(newTreeData, parentId, childsTreeArr);
            console.log(newTreeData);
            this.setState({ fetchedData: newData, treeData: newTreeData });
        }
    };

    /**
     * Ads TreeNode data into Tree data object which will rerender Tree.
     * 
     * @param {*} treeData 
     * @param {*} parentId 
     * @param {*} childData 
     * @returns 
     */
    insertTreeChildren = (treeData, parentId, childData) => {
        for (let i = 0; i < treeData.length; i++) {
            if (treeData[i].key === parentId) {
                childData.forEach(element => {
                    element.key = parentId + '#|#' + element.relType + '#|#' + element.key;
                });
                treeData[i].children = childData;
                return;
            } else if (treeData[i].children) {
                this.insertTreeChildren(treeData[i].children, parentId, childData);
            }
        }
    };

    /**
     * AntDesign TreeNode title renderer. Combines action button entry type tag and entry name.
     * 
     * @param {*} data 
     * @returns 
     */
    renderTreeNodeTitle = (data) => {
        // console.log(data);
        const { t } = this.props;

        if (data.id === TOO_MUCH_RECORDS_IN_SUBTREE && data.title === TOO_MUCH_RECORDS_IN_SUBTREE) {
            return <Alert message={t('app.entry.view.childHierarchyTooMuchToBear')} type="warning" showIcon />;
        } else {
            let entryTypeNameList = [];
            if (this.props.entryTypesRequestResult.getState().isDone()) {
                entryTypeNameList = this.props.entryTypesRequestResult.getData().map((item, key) => { return { type: item.type, name: item.name, color: item.properties.typeColor }; });
            }
            // style={{overflow:'hidden',textOverflow:"ellipsis",whiteSpace:"nowrap"}}
            return <div style={{ display: 'flex', flexDirection: 'row', whiteSpace: "nowrap" }}>
                <Button type="dashed" shape="circle" icon={<TableOutlined />} onClick={() => this.props.onTreeOpenTable(data.id, data.key)} size="small" />
                <EntryTypeTag entryTypeName={data.type} entryTypeNameList={entryTypeNameList}></EntryTypeTag>

                <Link className="linkEntryChildHierarchy" to={`/entry/${data.id}`} target="_blank" title={data.title}>{data.title}</Link>
            </div>
        }
    };

    /**
     * Helper for getting leaf id from hierarchy id path
     * 
     * @param {string} idPath 
     * @returns leaf id in id path
     */
    getItemIdFromIDPath = (idPath) => {
        return idPath.split("#|#")[idPath.split("#|#").length - 1];
    };

    resetTable = () => {
        this.setState({ selectedParent: null });
    };
    renderTreeNodes = (data) => {
        return data.map(item => {
            if (item.children) {
                console.log('ifdddddddddddddddddddddd');
                if (item.children && item.children.length >= 30) {
                    return (
                        <Tree
                            key={item.key}
                            selectedItemId={item.id}
                            onLoadData={this.onLoadData}
                            selectedItemKey={item.key}
                            title={this.renderTreeNodeTitle(item)}
                            loading={this.state.loading} >
                            <SearchTree
                                data={item.children}
                                renderTreeNodes={this.renderTreeNodes} />
                        </Tree>
                    )
                }
                else {
                    return (
                        <Tree
                            key={item.key}
                            selectedItemId={item.id}
                            selectedItemKey={item.key}
                            onLoadData={this.onLoadData}
                            title={this.renderTreeNodeTitle(item)}
                            loading={this.state.loading}
                        >

                            {this.renderTreeNodes(item.children)}
                        </Tree>
                    )
                }
            }
            return <Tree
                key={item.key}
                selectedItemId={item.id}
                onLoadData={this.onLoadData}
                selectedItemKey={item.key}
                title={this.renderTreeNodeTitle(item)}
                loading={this.state.loading} />
        })
    }
    displayMore = () => {
        this.setState({ limit: this.state.limit + 30 })
    }

    render() {
        return <>
            <SearchTree
                data={this.state.treeData}
                renderTreeNodes={this.renderTreeNodes} />
        </>
    }
}

export default withTranslation()(OutsideTheHierarchy);
