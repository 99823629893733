import React from "react";
import { Button, Input, Table, Modal, Upload, Alert,Popconfirm } from "antd";
import { UploadOutlined, InboxOutlined, DownloadOutlined, LinkOutlined,UserOutlined,DeleteOutlined,EyeOutlined,EyeInvisibleOutlined} from '@ant-design/icons';
//import Icon from '@ant-design/icons';
import { Icon } from '@ant-design/compatible';
import { withTranslation} from 'react-i18next'
import { config } from "../../../config/Config";
import { downloadFile } from "../../../utils/FileHelper";
import { isEmptyObject, isEmptyValue, isUndefined } from "../../../utils/JsObjectHelper";
import { createBaseOptions, fetchJson, METHOD_POST } from "../../../utils/SuperAgentFetch";
import { fetchDeleteFile } from "../../../apicalls/fetchDeleteFile";
import { notificationSuccess } from "../../../utils/NotificationsHelper";
import { notificationError } from "../../../utils/NotificationsHelper";


const { Column } = Table;
const { Search } = Input;
const { Dragger } = Upload;

class EntryFiles extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isUploadVisible: false,
            isUploadingData: false,
            messageType: "",
            messageTitle: "",
            uploadFileList: [],
            searchNameText: [],
            entryFilePageSize: (isEmptyObject(localStorage.getItem("entryFilePageSize")) ? 30 : parseInt(localStorage.getItem("entryFilePageSize")) ),
            fileUrl:'',
            filePreview:false,
            fileName:''
        };

        this.filterFiles = this.filterFiles.bind(this);
        this.handleUpload = this.handleUpload.bind(this);
    }

    componentDidMount() {
        this.props.onMount(this.props.entry.id);
    }

    componentWillUnmount() {
        this.props.onUnmount();
    }

    showTotal = (total) => {
        return `(${total})`;
    };

    handlePageSizeChanged = (current, page) => {
        localStorage.setItem("entryFilePageSize", page);
        this.setState({ entryFilePageSize: page });
    };

    filterFiles = (searchValue) => {
        this.setState({searchNameText: searchValue.split(' ')});
    };

    openUploadDialog = () => {
        this.setState({ isUploadVisible: true });
    };

    closeUploadDialog = () => {
        this.setState({ 
            isUploadVisible: false,
            uploadFileList: [],
            messageType: "",
            messageTitle: "",
        });
    };

    handleUpload = () => {
        const {t} = this.props;
        const { uploadFileList } = this.state;
        const formData = new FormData();
        uploadFileList.forEach(file => {
            formData.append('editor_id', '');
            formData.append('entry_id', this.props.entry.id);
            formData.append('file', file);
        });

        console.log(uploadFileList);
        console.log(formData);

        this.setState({ isUploadingData: true });

        /** @type RequestOptions */
        let options = createBaseOptions();
        options.method = METHOD_POST;
        options["body"] = formData;

        let r = fetchJson(`${config.url.FILES_URL}/upload`, options)
        r.then(json => {
            //console.log(json);
            this.setState({ 
                isUploadingData: false,
                uploadFileList: [],
                messageType: "success",
                messageTitle: t("app.entry.tools.fileList.uploadInfoSuccess"),
            });
            this.props.onMount(this.props.entry.id, true);
        }).catch(error => {
            console.log(error);
            this.setState({ 
                isUploadingData: false,
                messageType: "error",
                messageTitle: t("app.entry.tools.fileList.uploadInfoError"),
            });
        }).finally(()=>{
            this.props.refreshCount();
        });
   
    };

    renderFileIcon = (text, record) => {
        let iconType = "file-unknown";
        switch (record.contentType) {
            case "image/jpeg":
            case "image/png":
                iconType="file-image";
                break;
            case "application/vnd.ms-excel":
            case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                iconType="file-excel";
                break;
            case "application/msword":
            case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                iconType="file-word";
                break;
            case "application/vnd.ms-powerpoint":
            case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
                iconType="file-ppt";
                break;
            case "application/zip":
            case "application/gzip":
            case "application/vnd.rar":
            case "application/x-7z-compressed":
            case "application/x-zip-compressed":
                iconType="file-zip";
                break;
            case "application/pdf":
                iconType="file-pdf";
                break;
            case "application/vnd.oasis.opendocument.text":
            case "text/csv":
            case "application/rtf":
            case "text/plain":
                iconType="file-text";
                break;
            default:
                iconType="file-unknown";
        };

        return <Icon style={{ fontSize: '22px'}} type={iconType}></Icon>;
    };

    downloadFile(evnt, record) {
        downloadFile(`${config.url.FILES_URL}/file/${record.id}`, record.name);
    };

    copyFileLink(evnt, record) {
        let fileUrl = config.url.FILES_URL + '/file/' + record.id;

        function fallbackCopyTextToClipboard(text) {
            var textArea = document.createElement("textarea");
            textArea.value = text;
            // Avoid scrolling to bottom
            textArea.style.top = "0";
            textArea.style.left = "0";
            textArea.style.position = "fixed";
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            try {
                var successful = document.execCommand('copy');
                var msg = successful ? 'successful' : 'unsuccessful';
                console.log('Fallback: Copying text command was ' + msg);
            } catch (err) {
                console.error('Fallback: Oops, unable to copy', err);
            }
            document.body.removeChild(textArea);
        }

        if (!navigator.clipboard) {
            fallbackCopyTextToClipboard(fileUrl);
            return;
        }
        navigator.clipboard.writeText(fileUrl).then(function() {
            console.log('Async: Copying to clipboard was successful!');
        }, function(err) {
            console.error('Async: Could not copy text: ', err);
        });
    };
    handleDelete =(record)=>{
        fetchDeleteFile(record.id,this.deletionStatus)
    }
    deletionStatus =(statusOk)=>{
        const {t} = this.props;
        if(statusOk){
            notificationSuccess(t('app.entry.tools.fileList.alertFileDeletedSuccessTitle'),t('app.entry.tools.fileList.alertFileDeletedSuccessDescription'));
            this.props.refreshList(this.props.entry.id);
        }
        else{
            notificationError(t('app.entry.tools.fileList.alertFileDeletedErrorTitle'),t('app.entry.tools.fileList.alertFileDeletedErrorDescription'));
        }
        this.props.refreshCount();
    }
    fileViewer = (file)=> {
        console.log(file);
        let fileUrl = config.url.FILES_URL + '/file/' + file.id;
        this.setState({fileUrl:fileUrl,filePreview:true,fileName:file.name});
      }
      closePreview =()=>{
        this.setState({fileUrl:'',filePreview:false,fileName:''})
      }  
    render() {
        const {t} = this.props;

        const uploaderProps = {
            onRemove: file => {
                this.setState(state => {
                    const index = state.uploadFileList.indexOf(file);
                    const newFileList = state.uploadFileList.slice();
                    newFileList.splice(index, 1);
                    return {
                        uploadFileList: newFileList,
                    };
                });
            },
            beforeUpload: file => {
                this.setState(state => ({
                    uploadFileList: [file],
                }));
                return false;
            },
        };

        let paginationSetup = {
            showSizeChanger: true, 
            size:"small", 
            showTotal: this.showTotal, 
            defaultPageSize: this.state.entryFilePageSize, 
            pageSizeOptions: ['10', '20', '30', '50', '100'],
            onShowSizeChange: this.handlePageSizeChanged
        };

        let isLoading = !this.props.entryFilesRequestResult.getState().isDone();

        let extensionFilters = [];
        let userFilters = [];

        let title = (<div>
            <span><UploadOutlined title={t('app.entry.tools.fileList.uploadTitle')} style={{marginRight: '15px'}} /></span>
            <span>{t('app.entry.tools.fileList.uploadTitle')}</span>
        </div>);

        if (!isLoading && !isUndefined(this.props.entryFilesRequestResult.getData())) {
            extensionFilters = Array.from(new Set(this.props.entryFilesRequestResult.getData().map(itm => itm.extension))).map(
                                    itmName => {
                                        return { text: itmName, value: itmName };
                                    }
                                );
            userFilters = Array.from(new Set(this.props.entryFilesRequestResult.getData().map(itm => (isEmptyValue(itm.userName) ? "---" : itm.userName)))).map(
                itmName => {
                    return { text: itmName, value: itmName };
                }
            );
        }

        let alertMessage = null;
        if (!isEmptyValue(this.state.messageType)) {
            alertMessage = <Alert 
                                message={this.state.messageTitle} 
                                type={this.state.messageType} 
                                //description={this.state.messageDescription}
                                showIcon 
                                style={{ marginBottom: '15px'}} />;
        }
        const iframeSupportedFormats = [
            "image/jpeg",
            "image/png",
            "application/pdf",
            "text/plain",
            'image/svg+xml',
        ];
        return <div><Table 
                    dataSource={this.props.entryFilesRequestResult.getData()}
                    rowKey= {record => record.id}
                    size="small"
                    loading={isLoading}
                    scroll={{
                        x:true
                    }
                    }
                    pagination={paginationSetup}
                    title={() => <div style={{display:'flex'}}>
                        <Button type="primary" style={{marginRight: '15px'}} onClick={this.openUploadDialog}><UploadOutlined /> {t('app.entry.tools.fileList.btnUploadFile')}</Button>
                        <Search
                            placeholder={t('app.entry.tools.fileList.searchBoxHint')}
                            onSearch={this.filterFiles}
                            style={{ width: '100%' }}
                            allowClear
                        />
                        </div>}
                >
                    <Column
                        title={t('app.entry.tools.fileList.tblAction')}
                        key="action"
                        width={120}
                        render={(text, record) => {
                            console.log(record);
                           let preview = iframeSupportedFormats.find((el)=>record.contentType === el)
                           return <span>
                             <Button title={'View'} size="small" onClick={() => {this.fileViewer(record)}} disabled={!preview} icon={preview?<EyeOutlined />:<EyeInvisibleOutlined />}></Button>
                                <Button title={t('app.entry.tools.fileList.tblActionDownloadTitle')} size="small" onClick={(e) => {this.downloadFile(e,record)}} icon={<DownloadOutlined />}></Button>
                                <Button title={t('app.entry.tools.fileList.tblActionCopyLinkTitle')} size="small" onClick={(e) => {this.copyFileLink(e,record)}} icon={<LinkOutlined />}></Button>
                                <Popconfirm title={t('app.entry.tools.fileList.tooltipDeleteTitle')} okText={t("app.entry.tools.fileList.tooltipDeleteOk")} cancelText={t("app.entry.tools.fileList.tooltipDeleteCancel")} onConfirm={() => this.handleDelete(record)}>
                                <Button title={t('app.entry.tools.fileList.tblActionDeleteTitle')} size="small" icon={ <DeleteOutlined  />}></Button>
                       
                    </Popconfirm>
                            </span>
                            }}
                    />
                    <Column
                        key="icon"
                        width={40}
                        render={this.renderFileIcon}
                    />
                    <Column 
                        title={t('app.entry.tools.fileList.tblName')}
                        dataIndex="name" 
                        key="name" 
                        render={(text, record) => (
                            <span><b>{record.name}</b></span>
                        )}
                        filteredValue= {this.state.searchNameText}
                        onFilter= {(value, record) => record.name.toLowerCase().includes(value.toLowerCase())}
                        defaultSortOrder='ascend'
                        sorter={(a, b) => a.name.localeCompare(b.name)}
                    />
                    <Column 
                        title={t('app.entry.tools.fileList.tblDate')}
                        dataIndex="created" 
                        key="created" 
                        render= {(text, record) => { return t('datetime', {date : new Date(text)}) }}
                        sorter={(a, b) => new Date(a.created).getTime() - new Date(b.created).getTime()}
                    />
                    <Column 
                        title={t('app.entry.tools.fileList.tblExtension')}
                        dataIndex="extension" 
                        key="extension" 
                        filters= {extensionFilters}
                        onFilter={(value, record) => record.extension.indexOf(value) === 0}
                    />
                    <Column 
                        title={t('app.entry.tools.fileList.tblUserName')}
                        dataIndex="userName"
                        key="userName" 
                        filters= {userFilters}
                        onFilter={(value, record) => (isEmptyValue(record.userName) ? value === "---" : record.userName.indexOf(value) === 0)}
                        render={(text,record)=><a  href={"/id2entry/" + record.userId} target="_blank" ><UserOutlined /> {text}</a>}
                    />
                </Table>
                <Modal
                    title={this.state.fileName}
                    visible={this.state.filePreview}
                    onCancel={this.closePreview}
                    footer={false}
                    width={'70%'}
                >
                    <iframe src={this.state.fileUrl} title={this.state.fileName} style={{ width: '100%', height: '75vh' }} />
                </Modal>
                <Modal
                    title={title}
                    visible={this.state.isUploadVisible}
                    cancelText={t('app.entry.tools.fileList.uploadBtnBack')}
                    cancelType={this.state.btnType}
                    onOk={this.closeUploadDialog}
                    onCancel={this.closeUploadDialog}
                    okButtonProps={{ hidden: true }}
                    cancelButtonProps={{ loading: this.state.isUploadingData }}
                >
                    <div>
                        {alertMessage}
                        <Dragger 
                            name='file'
                            multiple={false}
                            showUploadList={{ showPreviewIcon: false, showDownloadIcon: false, showRemoveIcon: true }}
                            fileList={this.state.uploadFileList}
                            {...uploaderProps}
                        >
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">{t('app.entry.tools.fileList.uploadAreaTitle')}</p>
                            <p className="ant-upload-hint">
                                {t('app.entry.tools.fileList.uploadAreaText')}
                            </p>
                        </Dragger>
                        <Button
                            type="primary"
                            onClick={this.handleUpload}
                            disabled={this.state.uploadFileList.length === 0}
                            loading={this.state.isUploadingData}
                            style={{ marginTop: 16 }}
                            >
                            {this.state.isUploadingData ? t('app.entry.tools.fileList.uploadAreaBtnUploading') : t('app.entry.tools.fileList.uploadAreaBtnStartUpload')}
                        </Button>
                    </div>
                </Modal>
                </div>;
    }
}

export default withTranslation() (EntryFiles);