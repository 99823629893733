/**
 * Builds error params object for use in translate rewrite.
 * 
 * @param {object} record 
 * @returns errorParams object. Returns null if errorType is unknown
 */
export const getErrorParamsObject = (record) => {
    let errParams = {};
    switch (record.errorType) {
        case "ENTRY_TYPE_IS_NOT_ALLOWED":
            errParams = {entryType: record.errorParams.entryType, folderId: record.errorParams.folderId, folderName: record.errorParams.folderName};
            break;
        case "ENTRY_TYPE_MORE_RESULTS":
            errParams = {entryType: record.errorParams.entryType};
            break;
        case "MISSING_OBJECT_DEFINITON":
            errParams = {sheetName: record.sheetName};
            break;
        case "DELETED_OBJECT_DEFINITON":
            errParams = {sheetName: record.sheetName,definitionId: record.errorParams.definitionId};
            break;
        case "HEADER_DUPLICATED_NAMES":
            errParams = {duplicatedHeaderNames: record.errorParams.duplicatedHeaderNames};
            break;
        case "HEADER_MISSING_REQUIRED":
            errParams = {headerName: record.errorParams.headerName};
            break;
        case "HEADER_ATTRIBUTE_NOT_EXISTS":
            errParams = {headerName: record.errorParams.headerName, entryType: record.errorParams.entryType};
            break;
        case "ENTRY_ROW_RUD_UNKNOWN_VALUE":
        case "ENTRY_MUST_EXISTS_FOR_RUD":
            errParams = {rud: record.errorParams.rud};
            break;
        case "ATTRIBUTE_TYPE_NOT_SUPPORTED":
            errParams = {attributeType: record.errorParams.attributeType};
            break;
        case "MISSING_ATTRIBUTE_DEFINITON":
        case "MISSING_REQUIRED_ATTRIBUTE_VALUE":
            errParams = {attribute: record.errorParams.attribute};
            break;
        case "UNSUPPORTED_ATTRIBUTE_COLUMN_TYPE":
            errParams = {attribute: record.errorParams.attribute, columnType: record.errorParams.columnType};
            break;
        case "ENTRY_BELONGS_TO_ANOTHER_FOLDER":
            errParams = {aphinitId: record.errorParams.aphinitId, folderId: record.errorParams.folderId, folderName: record.errorParams.folderName};
            break;
        case "ENTRY_IS_FOLDER":
        case "ENTRY_NOT_EXISTS":
        case "ENTRY_IS_DELETED":
        case "RELATION_ROW_NO_RIGHTS":
            errParams = {aphinitId: record.errorParams.aphinitId};
            break;
        case "RELATION_ROW_AD_UNKNOWN_VALUE":
            errParams = {ad: record.errorParams.ad};
            break;
        case "RELATION_ROW_TEMP_ID_NOT_FOUND":
            errParams = {tempId: record.errorParams.tempId};
            break;
        case "RELATION_ROW_REL_NAME_NOT_FOUND":
            errParams = {relName: record.errorParams.relName};
            break;
        case "RELATION_ROW_TARGET_TYPE_NOT_ALLOWED":
            errParams = {relName: record.errorParams.relName, definitionType: record.errorParams.definitionType};
            break;
        case "RELATION_ROW_TARGET_PARENT_FOLDER_NOT_ALLOWED":
            errParams = {relName: record.errorParams.relName, folderId: record.errorParams.folderId, folderName: record.errorParams.folderName};
            break;
        case "NO_HEADER":
        case "ENTRY_ROW_MISSING_ENTRY_IDENTIFIER_VALUE":
        case "ENTRY_ROW_APHINIT_IDENTIFIER_MUST_BE_DEFINED":
        case "NOT_SUPPORTED_YET":
        case "RELATION_ROW_MISSING_SOURCE_ENTRY_IDENTIFIER_VALUE":
        case "RELATION_ROW_MISSING_TARGET_ENTRY_IDENTIFIER_VALUE":
        case "RELATION_ROW_MISSING_RELATION_NAME_VALUE":
        case "RELATION_ROW_APHINIT_IDENTIFIER_MUST_BE_DEFINED":
        case "RELATION_ROW_SOURCE_OBJECT_DEFINITION_NOT_FOUND":
            break;
        default:
            errParams = null;
    }

    return errParams;
};