import React from 'react';
import PropTypes from 'prop-types';
import { Button, Input, Select, Descriptions, Alert, Form, Modal, Checkbox } from 'antd';
import { SearchOutlined, SaveOutlined, StarOutlined,StarFilled } from '@ant-design/icons';
import 'antd/dist/antd.css';
import { withTranslation } from 'react-i18next'
import { entryTypeNameShape } from '../../shapes/EntryTypeNameShape';
//import ButtonGroup from 'antd/lib/button/button-group';
import { notificationSuccess } from '../../utils/NotificationsHelper';
import { entryTypesRequestResult } from '../../shapes/RequestResult';
import AdvancedSearchSubQuery from './AdvancedSearchSubQuery';
import { isEmptyValue, isUndefined } from '../../utils/JsObjectHelper';
import { isMobile } from '../../utils/AdaptiveLayout';
import debounce from 'lodash/debounce';
//import update from 'immutability-helper';

const Option = Select.Option;

class AdvancedSearchForm extends React.Component {

	formRef = React.createRef();

	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.state = {
			data: [],
			selectedEntryType: null,
			isSubQueryError: false,
			searchData: null,
			name: null,
			isModalVisible: false,
			disabledButton: false,
			checkbox: false,
			validator: false,
			isQuerySavedName: false,
		};
		this.onInputChange = debounce(this.onInputChange, 500);
	}

	componentDidMount() {
		this.props.onMount();
		this.moveButton();
	}
	componentWillUnmount() {
		localStorage.removeItem('searchHeader');
	}
	componentDidUpdate(prevProps, prevState) {
		if(prevProps.savedQueries !== this.props.savedQueries && this.props.savedQueries){
			this.checkIfQueryIsSaved();
		}
		if (prevProps.propData !== this.props.propData ) {
			this.checkIfQueryIsSaved(this.props.propData);
			this.formRef.current.setFieldsValue({ advancedValue: this.props.propData.value.advancedValue, advancedType: this.props.propData.value.advancedType })
			this.setState({
				data: this.props.propData.value.dynamicConditions,
				selectedEntryType: this.props.propData.value.advancedType
			})
		}
	}

	handleSubQueryChange = (newData) => {
		if (this.state.isSubQueryError) {
			this.setState({ isSubQueryError: !this.validateDynamicConditions(newData) });
		}
		this.setState({ data: newData });
		this.setState({ disabledButton: this.validateGroup(newData) });
		this.checkIfQueryIsSaved();
	};

	handleEntryTypeChanged = (entryType) => {
		this.setState({ selectedEntryType: entryType, data: [] });
		this.checkIfQueryIsSaved();
	};

	handleSubmit = (e) => {
		e.preventDefault();
		if (this.state.isSubQueryError || !this.validateDynamicConditions(this.state.data)) {
			this.setState({ isSubQueryError: true });
			return;
		}

		this.formRef.current.validateFields()
			.then(values => {
				values.dynamicConditions = this.state.data;
				this.props.onSearch(values);
			});
	};

	newCustomization = () => {
		let settings = { userId: this.props.user.id, type: 'search', name: this.state.name, value: this.state.searchData }
		if (this.state.checkbox) {
			settings.id = this.props.savedQueries.find((el) => el.name === this.state.name).id;
			this.props.updateCustomization(settings, this.connectedSuccess);
		}
		else {
			this.props.saveNewUserCustomization(settings, this.connectedSuccess);
		}

	};

	handleSave = (e) => {
		if(this.state.isQuerySavedName){
			return
		}
		e.preventDefault();
		if (this.state.isSubQueryError || !this.validateDynamicConditions(this.state.data)) {
			this.setState({ isSubQueryError: true });
			return;
		}

		this.formRef.current.validateFields()
			.then(values => {
				values.dynamicConditions = this.state.data;
				this.setState({ searchData: values })
			});

		this.setState({ isModalVisible: true })
	};

	setName = (e) => {
		let useName = this.props.savedQueries.find((el) => el.name === e.target.value)
		if (useName) {
			this.setState({ name: e.target.value, validator: true });
		}
		else if (useName && this.state.checkbox) {
			this.setState({ name: e.target.value, validator: false });
		}
		else {
			this.setState({ name: e.target.value, validator: false, checkbox: false });
		}

	};

	handleCancel = () => {
		this.setState({ isModalVisible: false,validator: false, })
	}

	connectedSuccess = (json) => {
		const { t } = this.props;
		if (json) {
			notificationSuccess(t('app.userPage.notificationTitle'))
			this.setState({ isModalVisible: false });
		}

	}
	validateGroup = (attributeSearch) => {
		let disabledButton = false;
		attributeSearch.forEach((element) => {
			if (element.type === 'group') {
				if (element.conditions.length === 0) {
					disabledButton = true;
				}
				if (element.conditions.length > 0) {
					element.conditions.forEach((el) => {
						if (el.type === 'group') {
							this.validateGroup([el]);
						}
						if (el.type === 'condition') {
							if (!this.validateDynamicConditions([el])) {
								disabledButton = true;
							}

						}
					});
				}
			}
			if (element.type === 'condition') {
				if (!this.validateDynamicConditions([element])) {
					disabledButton = true;
				}
			}
		});
		return disabledButton
	}

	validateDynamicConditions = (data) => {
		let returnState = true;
		data.forEach((d) => {
			if (d.type === 'condition') {
				if (isEmptyValue(d.condition.attribute))
					returnState = false;
				else {
					let attDefinition = this.props.entryTypeRequestResult.getData()
						.find(et => et.type === this.state.selectedEntryType).properties.attributes
						.find(att => att.techName === d.condition.attribute);
					switch (attDefinition.type) {
						case "string":
							if (isEmptyValue(d.condition.conditionType) ||
								(isEmptyValue(d.condition.value1) && d.condition.conditionType !== "empty" && d.condition.conditionType !== "notempty")
							) {
								returnState = false;
							}
							if (d.condition.conditionType === "containsword" || d.condition.conditionType === "notcontainsword") {
								if (/\s/g.test(d.condition.value1)) {
									returnState = false;
								}
							}

							break;
						case "number":
							if (isEmptyValue(d.condition.conditionType) ||
								(isEmptyValue(d.condition.value1) && d.condition.conditionType !== "empty" && d.condition.conditionType !== "notempty") ||
								(d.condition.conditionType === "between" && isEmptyValue(d.condition.value2)))
								returnState = false;
							break;
						case "date":
							if (isEmptyValue(d.condition.conditionType) ||
								isEmptyValue(d.condition.value1) ||
								(d.condition.conditionType === "between" && isEmptyValue(d.condition.value2)))
								returnState = false;
							break;
						default:
							break;
					}
				}
			} else if (d.type === 'condition') {
				if (!this.validateDynamicConditions(d.conditions))
					returnState = false;
			}
		});
		return returnState;
	};
	changeCheckbox = (e) => {
		this.setState({ checkbox: e.target.checked })
	}
	moveButton = () => {
		const myToolPanel = document.querySelector('.ant-collapse-header');
		if (myToolPanel) {
			const targetElement = myToolPanel.querySelector('.ant-collapse-extra');
			const buttonCopy = document.querySelector('.buttonSave');

			if (targetElement && buttonCopy) {
				if (targetElement.firstChild) {
					targetElement.insertBefore(buttonCopy, targetElement.firstChild);
				} else {
					targetElement.appendChild(buttonCopy);
				}
			}
		}
	};
	checkIfQueryIsSaved = (propData) => {
		const { data } = this.state; 
		const advancedValue = this.formRef.current.getFieldValue('advancedValue');
		const selectedEntryType = this.formRef.current.getFieldValue('advancedType');
		const currentSearchData = {
		  advancedValue: advancedValue,
		  dynamicConditions: data,
		};
	  
		if (selectedEntryType) {
		  currentSearchData.advancedType = selectedEntryType;
		}

		const currentSearchDataString = JSON.stringify(currentSearchData);
		let isSaved = this.props.savedQueries.find((query) => {
		  const queryData = {
			advancedValue: query.value.advancedValue,
			dynamicConditions: query.value.dynamicConditions,
		  };
	  
		  if (query.value.advancedType) {
			queryData.advancedType = query.value.advancedType;
		  }
	  
		  const queryValueString = JSON.stringify(queryData);
		  return currentSearchDataString === queryValueString;
		});
		if(propData){
			isSaved = this.props.savedQueries.find((query) =>  query === propData)
		}
		let name = isSaved && isSaved.name?isSaved.name:false;
		 this.setState({ isQuerySavedName:name  });
	  };
	  
	onInputChange = (e) => {
		this.checkIfQueryIsSaved();
	};

	render() {
		const { t } = this.props;
		const options = this.props.entryTypeNameList
			.filter(et => { return (et.type !== "home"); })
			.sort((a, b) => a.name.localeCompare(b.name))
			.map(d => <Option value={d.type} key={d.type}>{d.name}</Option>);

		let entryTypeAttributes = [];

		let advancedSearchAttributeSection = null;

		if (!isUndefined(this.state.selectedEntryType) && this.props.entryTypeRequestResult.getState().isDone()) {
			let entryTypeLive = this.props.entryTypeRequestResult.getData().find((el) => el.type === this.state.selectedEntryType)
			if (isUndefined(entryTypeLive)) {
				entryTypeAttributes = ['entryTypeDeleted'];
			}
			if (!isUndefined(entryTypeLive)) {
				entryTypeAttributes = this.props.entryTypeRequestResult.getData()
					.find(et => et.type === this.state.selectedEntryType).properties.attributes
					.filter(at => at.isSearchable);

			}
			// entryTypeAttributes = this.props.entryTypeRequestResult.getData()
			//     .find(et => et.type === this.state.selectedEntryType).properties.attributes
			//     .filter(at => at.isSearchable);

			let subQueryError = (this.state.isSubQueryError ? <Alert message={t('app.advancedSearch.attributeConditionsErr')} type="warning" showIcon key="errMessage" /> : null);
			advancedSearchAttributeSection = <Descriptions.Item label={t('app.advancedSearch.attributesRowName')}>
				{subQueryError}
				{(
					(entryTypeAttributes.length < 1)
						?
						<Alert message={t('app.advancedSearch.entryTypeHasNoSearchInfo')} type="warning" showIcon />
						:
						(entryTypeAttributes[0] === 'entryTypeDeleted')
							? <Alert message={t('app.advancedSearch.errorEntryTypeDeleted')} type="error" showIcon />
							:
							<AdvancedSearchSubQuery
								key="subQuery"
								searchData={this.state.data}
								entryTypeAttributes={entryTypeAttributes}
								onDataChanged={this.handleSubQueryChange}
							>
							</AdvancedSearchSubQuery>
				)}
			</Descriptions.Item>;
		}
		let modal = <Modal title={t('app.advancedSearch.modalSaveSearch')} visible={this.state.isModalVisible} onCancel={this.handleCancel} destroyOnClose={true} footer={false}>
			<div>
				<Input placeholder={t('app.advancedSearch.placeholderModalInputSave')} onChange={this.setName}></Input>
				{this.state.validator ? <Alert
					style={{ marginTop: '20px' }}
					message={t('app.advancedSearch.queryAlredyExists')}
					description={t('app.advancedSearch.queryAlredyExistsDescription')}
					type="warning"
					showIcon
				/> : null}
				<div style={{display:'flex',justifyContent:'space-between', marginTop: '20px'}}>
				{this.state.validator ? <Checkbox checked={this.state.checkbox} onChange={(e) => this.changeCheckbox(e)}>{t('app.advancedSearch.overwriteTheQuery')}</Checkbox> : <div></div>}
				
				<div style={{ display: 'flex', justifyContent: 'end' }}>
					<Button type="default" onClick={() => this.handleCancel()} style={{ marginRight: '10px' }}>{t('app.advancedSearch.btnCancel')}</Button>
					<Button type="primary" disabled={!this.state.name || (this.state.validator && !this.state.checkbox)} onClick={() => this.newCustomization()}><SaveOutlined style={{ fontSize: '18px' }} />{t('app.advancedSearch.btnSave')}</Button></div>
			</div></div>
		</Modal>
		let buttonSave = <Button
			className='buttonSave'
			disabled={this.state.disabledButton}
			type="button"
			onClick={this.handleSave}
			title={t('app.advancedSearch.titleBtnSave')}
			icon={this.state.isQuerySavedName ? <StarFilled style={{ color: '#eeba2e'  }} />:<StarOutlined /> }
			style={{ marginTop: '15px', marginLeft: '10px' }}
		>
			{this.state.isQuerySavedName ?this.state.isQuerySavedName:null}
		</Button>
		return <div>
			{modal}
			<Form layout="inline" name="advanced_search_form" ref={this.formRef}>
				<Descriptions size="middle" bordered column={1} className="advancedSearchDescBox" layout={isMobile() ? "vertical" : "horizontal"}>
					<Descriptions.Item label={t('app.advancedSearch.nameDescRowName')}>
						<Alert message={t('app.advancedSearch.howToUseInfo')} type="info" showIcon />
						<Form.Item //validateStatus={advancedValueError ? 'error' : ''} help={advancedValueError || ''}
							name="advancedValue"
							rules={[{ required: true, message: t('app.advancedSearch.searchBoxValidatorMsg') }]}
							initialValue={!isUndefined(localStorage.getItem('searchHeader')) ? localStorage.getItem('searchHeader') : '*'}
						>
							<Input placeholder={t('app.advancedSearch.searchBoxHint')} onChange={this.onInputChange} className='searchFormItem' allowClear={true} />
						</Form.Item>
					</Descriptions.Item>
					<Descriptions.Item label={t('app.advancedSearch.entryTypeRowName')}>
						<Form.Item //validateStatus={advancedTypeError ? 'error' : ''} help={advancedTypeError || ''}
							name="advancedType">
							<Select
								showSearch
								className='searchFormItem'
								placeholder={t('app.advancedSearch.typeSelectHint')}
								optionFilterProp="children"
								filterOption={(input, option) =>
									option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}
								allowClear={true}
								onChange={this.handleEntryTypeChanged}
							>
								{options}
							</Select>
						</Form.Item>
					</Descriptions.Item >

					{advancedSearchAttributeSection}

				</Descriptions>
			</Form>
			<div>
				<Button type="primary"
					onClick={this.handleSubmit}
					icon={<SearchOutlined />} style={{ marginTop: '15px' }}
					disabled={this.state.disabledButton}
				/*disabled={
					isUndefined(this.formRef.current) ||
					(!isUndefined(this.formRef.current) && 
					//!this.formRef.current.isFieldsTouched(true) ||
					this.formRef.current.getFieldsError().filter(({ errors }) => errors.length).length > 0
					)
				}*/
				>
					{t('app.advancedSearch.searchBtnText')}
				</Button>
				{buttonSave}
			</div>
		</div>;
	}
}

AdvancedSearchForm.propTypes = {
	entryTypeNameList: PropTypes.arrayOf(entryTypeNameShape),
	entryTypeRequestResult: entryTypesRequestResult.isRequired,
	onSearch: PropTypes.func.isRequired
};

export default withTranslation()(AdvancedSearchForm);
