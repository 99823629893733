import React from "react";
import { isEmptyObject, isEmptyValue, isUndefined } from "../../utils/JsObjectHelper";
import { PageHeader, Tag, Row, Card, Spin, Alert } from "antd";
import EntryToolsPanel from "./view/EntryToolsPanel";
import ReactHtmlParser from 'react-html-parser';
import { entriesRequestResult, entryResultDetailShape, incomingRelationsRequestResult, outgoingRelationsRequestResult, entryTypesRequestResult } from "../../shapes/RequestResult";
import AttributesSection from '../../containers/detail/AttributesSection';
//import EntryChildTable from './view/EntryChildTable';
import EntryChildSection from './view/EntryChildSection';
import EntrySubFolders from './view/EntrySubFolders';
import EntryRelationsTable from './view/EntryRelationsTable';
import EntryTypeTag from '../controls/EntryTypeTag';
import { withTranslation } from 'react-i18next';


// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/plugins.pkgd.min.css';
import '../../froala_custom.css';

// Require Font Awesome.
import 'font-awesome/css/font-awesome.css';
import { config } from "../../config/Config";
import { replaceAttributsInDescription } from "./ReplaceDescription";
import DocumentViewer from "../object/descriptionLayout/DocumentViewer";
import { fetchTranslate } from "../../apicalls/locate/fetchTranslate";
//import { getTextColorFromBackgroundHex } from "../../utils/ColorHelper";

class EntryView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          translateDescription:null,
          loading:false,
          selectedLanguage:null
        };
    }

    componentDidMount() {
        this.props.onMount();
        if (isUndefined(localStorage.getItem('historyEntry'))) {
            let now = new Date();
            let arr = [];
            arr.push({ entryID: this.props.entryID, date: now })
            let str = JSON.stringify(arr)
            localStorage.setItem('historyEntry', str);
        }

    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.entryID !== this.props.entryID) {
            let arr = JSON.parse(localStorage.getItem('historyEntry'))
            let now = new Date();
            if (arr.length === 30) {
                arr.pop()
            }
            if (arr.find((el) => el.entryID === this.props.entryID)) {
                let index = arr.findIndex((el) => el.entryID === this.props.entryID)
                arr.splice(index, 1)
            }
            arr.unshift({ entryID: this.props.entryID, date: now })
            let str = JSON.stringify(arr)
            localStorage.setItem("historyEntry", str);
        }
    }
    isValidJson = (str) => {
        try {
            const parsedDescription = JSON.parse(str);
            return true
        } catch (error) {
            return false
        }
    }
    selectLanguage = (value)=>{
        this.setState({loading:true})
        let description =  this.props.entryRequestResult.getData().description;
        const tagRegex = /<[^>]*>/g;
            let tags = [];
            let placeholderHtml = description.replace(tagRegex, (match) => {
                let index = tags.push(match) - 1;
                return `[${index}]`; // Используем новый формат плейсхолдера
            });
            fetchTranslate(value,description,(data)=> this.setState({translateDescription:{description:data.translatedText},loading:false,selectedLanguage:value}),tags)
        // fetch('http://localhost:5000/translate', {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json'
        //     },
        //     body: JSON.stringify({
        //         q: description,
        //         source: 'auto',
        //         target: value,
        //          format: "html",
        //         api_key :'d791587f-f54f-4a20-bedd-10a675723104'
        //     })
        // })
        //     .then(response => response.json())
        //     .then(data =>{
        //         console.log(data);
                
        //     })
        //     // .then(data => {
        //     //     let translatedHtml = data.translatedText;
        //     //     // Удаление экранированных символов новой строки, обратных слешей и двойных кавычек
        //     //     translatedHtml = translatedHtml.replace(/\[(\d+)\]/g, (match, index) => tags[index]);
        //     //     translatedHtml = translatedHtml.replace(/\\n/g, ' ')
        //     //         .replace(/\\\\/g, '\\')
        //     //         .replace(/\\"/g, '"'); // Удаление экранированных двойных кавычек
        //     //     // Замена плейсхолдеров тегами
        //     //     console.log(translatedHtml);
        //     //     this.setState({translateDescription:{description:translatedHtml},loading:false,selectedLanguage:value})
        //     // })
        //     .catch(error => console.error('Error:', error));
    }
    showOriginal = ()=>{
        this.setState({translateDescription:null,selectedLanguage:null});
    }

    render() {
        const { t } = this.props;
        let showEntriAttributeContent = true;
        let entryTypeNameList = [];

        if (this.props.entryTypesRequestResult.getState().isDone()) {
            entryTypeNameList = this.props.entryTypesRequestResult.getData().map((item, key) => { return { type: item.type, name: item.name, color: item.properties.typeColor }; });
        }

        /** @type {RequestResult} */
        const rr = this.props.entryRequestResult;
        /** @type {RequestResult} */
        const rrchild = this.props.childrenRequestResult;

        if (isEmptyObject(rr) || rr.getState().isLoading() || isEmptyObject(rr.getData()))
            return <div>&nbsp;</div>;
        else if (rr.getData()) {
            /** @type {BaseEntryWithParent} */
            const entry = rr.getData();
            /** @type {Array<BaseEntry>|null} */
            let children = [];
            if (!isEmptyObject(rrchild) && rrchild.getState().isDone() && !isEmptyObject(rrchild.getData())) {
                children = rrchild.getData();
            }

            //Sub Folders a Childs se zobrazují pouze pro FOLDER a HOME
            let subFolders = null;
            let childs = null;
            let childsData = null;
            let relations = null;

            if (entry.type === "folder" || entry.type === "home") {
                showEntriAttributeContent = false;
                let defaultSubFoldersDisplayType = "icons";
                if (!isUndefined(entry.properties) && !isEmptyValue(entry.properties.subFolderDisplayType)) {
                    defaultSubFoldersDisplayType = entry.properties.subFolderDisplayType
                }
                //SUB FOLDERS
                subFolders = <EntrySubFolders
                    entry={entry}
                    childsDataList={children}
                    defaultDisplayType={defaultSubFoldersDisplayType}
                    entryTypeNameList={entryTypeNameList}
                ></EntrySubFolders>;

                //CHILDS
                childsData = children.filter(fol => { return fol.type !== "folder" }).map(
                    (item, key) => {
                        let itemType = item.type;
                        if (entryTypeNameList.length > 0) {
                            itemType = entryTypeNameList.find(eT => eT.type === item.type).name;
                        }
                        //return {id: item.id, entry: item.name, entryType: itemType, key: key, created: item.created, createdByUserName: item.createdByUserName};
                        return { entry: item.name, entryType: itemType, key: key, ...item };
                    }
                );

                //Notify user that childs are still loading
                if (rrchild.getState().isLoading()) {
                    childs = <Card loading={true}></Card>
                }

                //Dont display child box if there are no childrens
                if (childsData.length > 0) {
                    childs = <EntryChildSection
                        childsDataList={childsData}
                        folderProperties={entry.properties}
                        entryTypesRequestResult={this.props.entryTypesRequestResult}
                        entry={entry}></EntryChildSection>;
                }
            } else {
                relations = <EntryRelationsTable
                    entryRequestResult={this.props.entryRequestResult}
                    incomingRelationsRequestResult={this.props.incomingRelationsRequestResult}
                    outgoingRelationsRequestResult={this.props.outgoingRelationsRequestResult}
                    entryTypesRequestResult={this.props.entryTypesRequestResult}
                >
                </EntryRelationsTable>
            }

            let attSection = null;

            if (showEntriAttributeContent) {
                attSection = <AttributesSection
                    data={entry}
                    properties={entry.properties}
                    relationData={this.props.outgoingRelationsHolder}
                    isEditMode={false}
                    isLoadingRelations={this.props.outgoingRelationsRequestResult.getState().isLoading()}
                />
            }

            //Entry Type TAG
            let entryTypeTag = null;
            switch (entry.type) {
                case "home":
                    entryTypeTag = null;
                    break;
                case "folder":
                    entryTypeTag = <Tag color="#ffc069">{t('app.entry.view.entryTypeFolder')}</Tag>;
                    break;
                default:
                    entryTypeTag = <EntryTypeTag entryTypeName={entry.type} entryTypeNameList={entryTypeNameList}></EntryTypeTag>
                    /*if (entryTypeNameList.length > 0) {
                        let thisType = entryTypeNameList.find(t=>t.type===entry.type);
                        let tagTextColor = getTextColorFromBackgroundHex(thisType.color);
                        entryTypeTag = <Tag color={isEmptyValue(thisType.color) ? "blue" : thisType.color} style={{color:tagTextColor}}>{thisType.name}</Tag>;
                    } else {
                        entryTypeTag = <Tag color="blue">{entry.type}</Tag>;
                    }*/
                    break;
            }
            let description = this.state.translateDescription ? this.state.translateDescription :entry;
            let newDescription = replaceAttributsInDescription(description, this.props.outgoingRelationsRequestResult, this.props.incomingRelationsRequestResult, t);
            newDescription = this.isValidJson(newDescription)?JSON.parse(newDescription):newDescription
            let descriptionView = <div className="fr-element fr-view" style={{ padding: '0px' }}>
                {ReactHtmlParser(newDescription)}
            </div>
            if(window.innerWidth <= 1300 && entry.systemType === "object" && newDescription){
                if(newDescription.includes('DocumentLayoutTemplateMain')   || newDescription.includes('DocumentLayoutMain')){
                       descriptionView =  <DocumentViewer descriptionHtml={newDescription}/>
                }
            }
            let alert = this.state.translateDescription ?  <Alert style={{marginBottom:'20px'}} message="This text has been automatically translated by a machine translation system" type="info" showIcon />:null;

            return <div>
                <PageHeader
                    title={entry.name}
                    tags={entryTypeTag}
                    className="entryPageHeader"
                    extra={[
                        <EntryToolsPanel key="tools-panel" entry={entry} selectLanguage={this.selectLanguage} showOriginal={this.showOriginal} selectedLanguage={this.state.selectedLanguage} ></EntryToolsPanel>,
                    ]}
                >
                    {!isUndefined(entry.properties) && !isUndefined(entry.properties.folderDisplayLayout) && entry.properties.folderDisplayLayout === "folder" ? subFolders : null}
                    <Row className="pgContent"/*  type="flex" */>
                        <Spin tip={'Please wait, your text is currently being translated.'} spinning={this.state.loading} size="large">
                            {alert}
                        <div className="fr-box fr-basic fr-top">
                            {/* <div className="fr-wrapper"> */}
                            {descriptionView}
                            {/* </div>*/}
                        </div></Spin>
                    </Row>
                </PageHeader>

                {!isUndefined(entry.properties) && !isUndefined(entry.properties.folderDisplayLayout) && entry.properties.folderDisplayLayout !== "folder" || !isUndefined(entry.properties) && isUndefined(entry.properties.folderDisplayLayout) ? subFolders : null}
                {/* {subFolders} */}
                <br />

                {childs}

                {attSection}

                <br />

                {relations}

            </div>;
        }
    }
}

export default withTranslation()(EntryView);

EntryView.propTypes = {
    entryRequestResult: entryResultDetailShape,
    childrenRequestResult: entriesRequestResult,
    incomingRelationsRequestResult: incomingRelationsRequestResult,
    outgoingRelationsRequestResult: outgoingRelationsRequestResult,
    entryTypesRequestResult: entryTypesRequestResult.isRequired
};

