import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Card, Row, Col, Skeleton, Divider, Button, Select, Modal, Transfer, Spin, Space } from 'antd';
import { EditOutlined, PlusOutlined, SaveOutlined, UnlockOutlined, DeleteOutlined } from '@ant-design/icons';
import NextSectionButton from '../controls/NextSectionButton'
import GroupEdit from "../setup/GroupEdit"
import { isUndefined, isEmptyObject, isEmptyValue } from '../../utils/JsObjectHelper'
import { withTranslation } from 'react-i18next'
import GroupRights from "./GroupRights";
import { fetchGroupRights, fetchGroupRightsBreadCrumbs } from "../../apicalls/fetchGroupRights";
import { isMobile, isTablet } from "../../utils/AdaptiveLayout";

const { Option } = Select;

class Groups extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showGroupDialog: false,
            isGroupDialogCreateNew: true,
            groupFormData: {},
            targetKeys: this.props.groupUsersData,
            canConfirmUserChange: false,
            translates: {
                isSet: false,
                deleteTitle: "",
                deleteOk: "",
                deleteCancel: "",
            },
            foldersId:null,
            dataForGroupRights: [],
            loading:false
        };

        this.onMount = this.onMount.bind(this);
        this.refreshUsers = this.refreshUsers.bind(this);
        this.showDeleteConfirm = this.showDeleteConfirm.bind(this);
        this.setStateTranslates = this.setStateTranslates.bind(this);
    }

    componentDidMount() {
        this.onMount();
        this.refreshUsers(this.props.groupDetailId);
    }

    onMount() {
        this.props.onMount(this.props.groupDetailId);
    }

    /**
     * UNSAFE and deprecated lifecycle method!
     */
    /*UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.groupUsersData !== this.props.groupUsersData) {
            this.setState({
                targetKeys: nextProps.groupUsersData
            });
        }
    }*/
    componentDidUpdate(prevProps, prevState) {
        if (JSON.stringify(this.props.groupUsersData) !== JSON.stringify(prevProps.groupUsersData)) {
            this.setState({
                targetKeys: this.props.groupUsersData,
            });
        }
        if(prevProps.groupDetailId !== this.props.groupDetailId && this.state.dataForGroupRights.length>0){
            this.setState({
                foldersId:null,
                dataForGroupRights: [],
                loading:false
            });
        }
        
    }


    /*
    * Functions for Transfer control. For adding users to group.
    */

    filterOption = (inputValue, option) =>
    ((isUndefined(option.name) ? '' : option.name).indexOf(inputValue) > -1 ||
        (isUndefined(option.email) ? '' : option.email).indexOf(inputValue) > -1);

    handleChange = targetKeys => {
        this.setState({ targetKeys, canConfirmUserChange: true });
    };

    handleSearch = (dir, value) => {
        console.log('search:', dir, value);
    };

    refreshUsers = (groupId) => {
        this.props.getGroupUsers(groupId);
        this.setState({
            canConfirmUserChange: false,
            targetKeys: this.props.groupUsersData
        });
    };

    refreshGroupDetail = (groupId) => {
        this.props.getGroupDetail(groupId);
    };

    renderTransferItem = item => {
        const customLabel = (
            <span className="custom-item">
                {item.email}
            </span>
        );
        const customValue = `${item.email} (${item.name})`;

        return {
            label: customLabel, // for displayed item
            value: customValue, // for title and filter matching
        };
    };
    //END Transfer functions

    showDeleteConfirm = () => {
        Modal.confirm({
            title: this.state.translates.deleteTitle,
            content: <p><b>{this.props.groupDetailDataRequestResult.getData().name}</b></p>,
            okText: this.state.translates.deleteOk,
            okType: 'danger',
            cancelText: this.state.translates.deleteCancel,
            onOk() {
                console.log('OK');
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    showGroupDetail = (record) => {
        this.refreshUsers(record);
        this.refreshGroupDetail(record);
        this.props.history.push(`/setup/groups/` + record);
    }

    confirmUserChanges = () => {
        let deletedUsers = this.props.groupUsersData.filter(x => !this.state.targetKeys.includes(x));
        this.props.removeUsersFromGroup(this.props.groupDetailId, deletedUsers);
        let addedUsers = this.state.targetKeys.filter(x => !this.props.groupUsersData.includes(x));
        this.props.addUsers2Group(this.props.groupDetailId, addedUsers);
    }

    showEditGroup = () => {
        this.setState({
            isGroupDialogCreateNew: false,
            groupFormData: this.props.groupDetailDataRequestResult.getData(),
            showGroupDialog: true
        });
    }

    showNewGroup = () => {
        this.setState({
            isGroupDialogCreateNew: true,
            groupFormData: { id: null, name: '' },
            showGroupDialog: true
        });
    }

    closeEditGroup = () => {
        this.setState({
            showGroupDialog: false
        });
    }

    setStateTranslates = (translator) => {
        this.setState({
            translates: {
                isSet: true,
                deleteTitle: translator('setup.userManagement.groupDeleteTitle'),
                deleteOk: translator('setup.userManagement.usrDeleteYes'),
                deleteCancel: translator('setup.userManagement.usrDeleteNo')
            }
        });
    };
    createDataStructure = (rights, breadcrumbs) => {
        let structure = {};

        breadcrumbs.forEach(breadcrumb => {
            let currentLevel = structure;

            breadcrumb.forEach(folder => {
                if(!folder.name.includes("Draft folder for entry ID:")){
                if (!currentLevel[folder.id]) {
                    let rightsEntry = rights.find(rights => rights.entryId === folder.id);
                    currentLevel[folder.id] = {
                        key: folder.id,
                        id: folder.id,
                        name: folder.name,
                        rights: rightsEntry  ? rightsEntry.entryAccess : null,//rightsEntry.entryAccess !== "NONE" 
                        children: {}
                    };
                }
                currentLevel = currentLevel[folder.id].children;
            }
            });
        });

        const recurse = (obj) => {
            let result = [];
            for (let prop in obj) {
                let item = { ...obj[prop] };
                if (Object.keys(item.children).length > 0) {
                    item.children = recurse(item.children);
                } else {
                    delete item.children;
                }
                result.push(item);
            }
            return result;
        }

        return recurse(structure);
    }
    showGroupRights = async () => {
        if(this.props.groupDetailId){
          let rightsOnFolders = await fetchGroupRights(this.props.groupDetailId);
          console.log(rightsOnFolders);
          let foldersId = rightsOnFolders.map((el) => el.entryId);
          
          this.setState({foldersId:foldersId, loading: true });
          let breadCrumbs = await fetchGroupRightsBreadCrumbs(foldersId);
          let data = this.createDataStructure(rightsOnFolders, breadCrumbs);
          this.setState({ dataForGroupRights: data,loading: false});
        }
    }


    //RENDER
    render() {
        const { t } = this.props;

        if (!this.state.translates.isSet) {
            this.setStateTranslates(t);
        }

        let isAdminGroupSelected = false;

        let isDetailLoading = (
            !this.props.allUsersDataRequestResult.getState().isDone() ||
            !this.props.groupListRequestResult.getState().isDone() ||
            this.props.groupUsersRequestResult.getState().isLoading())

        let options = [];
        if (this.props.groupListRequestResult.getState().isDone()) {
            options = this.props.groupListRequestResult.getData()
                .filter(g => g.name !== "AP_ADMINS" || this.props.isAdmin)
                .map(d => <Option key={d.id}>{d.name}</Option>);

            //ensure no change in super admin group if user knows group id
            if (!this.props.isAdmin) {
                isAdminGroupSelected = this.props.groupListRequestResult.getData().find(g => g.name === "AP_ADMINS").id === this.props.groupDetailId;
            }
        }

        let groupModalTitle = (this.state.isGroupDialogCreateNew ? t('setup.userManagement.groupNewTitle') : t('setup.userManagement.groupEditTitle'));

        let groupCardTitle = t('setup.userManagement.groupTitle') +
            (
                //isUndefined(this.props.groupDetailData) || isEmptyObject(this.props.groupDetailData) ? 
                !this.props.groupDetailDataRequestResult.getState().isDone() || isEmptyObject(this.props.groupDetailDataRequestResult.getData()) ?
                    '' :
                    ': ' + this.props.groupDetailDataRequestResult.getData().name
            );

        let userList = (this.props.allUsersDataRequestResult.getState().isDone() ? this.props.allUsersDataRequestResult.getData() : []);
        let groupRights = null;
        if (this.state.dataForGroupRights) {
            groupRights = <GroupRights loading={this.state.loading} foldersId={this.state.foldersId} data={this.state.dataForGroupRights} />
        }
        return ( 
            <div>
                <Divider>{t('setup.userManagement.groupSection')}</Divider>
                <Space direction="vertical" style={{ width: '100%' }}>
                    <Row type="flex" justify="space-between" gutter={[24, 30]}>
                        <Col>
                            <Select
                                showSearch
                                placeholder={t('setup.userManagement.groupSelect')}
                                defaultActiveFirstOption={false}
                                value={this.props.groupDetailId}
                                style={{ width: isMobile()? 200: 300 }}
                                optionFilterProp="children"
                                onChange={this.showGroupDetail}
                                filterOption={
                                    (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {options}
                            </Select>
                        </Col>
                        <Col>
                            <Button disabled={true} type="primary" onClick={this.showNewGroup}><PlusOutlined /> {t('setup.userManagement.groupBtnAdd')}</Button>
                        </Col>
                    </Row>
                    <Row gutter={[0, 30]} className="mainRowGroup">
                        <Col span={12} className="colGroup">
                            <Card
                                className="groupsCard"
                                title={groupCardTitle}
                                actions={[
                                    <Button onClick={this.confirmUserChanges} type="primary"
                                        disabled={!this.props.showDetail || !this.state.canConfirmUserChange || isAdminGroupSelected}
                                    >
                                        <SaveOutlined key="save" title="Confirm changes" /> {t('setup.userManagement.groupBtnConfirmUsers')}
                                    </Button>,
                                    <Button onClick={this.showEditGroup} type="default" disabled={true || !this.props.showDetail}>
                                        <EditOutlined key="edit" title="Edit name" /> {t('setup.userManagement.groupBtnEdit')}
                                    </Button>,
                                    <Button onClick={this.showGroupRights} type="default" disabled={ !this.props.groupDetailId}>
                                        <UnlockOutlined key="rights" title="Show group rights" /> {t('setup.userManagement.groupBtnShowRights')}
                                    </Button>,
                                    <Button onClick={this.showDeleteConfirm} type="danger" disabled={true || !this.props.showDetail}>
                                        <DeleteOutlined key="delete" title="Delete" /> {t('setup.userManagement.groupBtnDeleteGroup')}
                                    </Button>
                                ]}
                            >
                                <Modal
                                    title={groupModalTitle}
                                    visible={this.state.showGroupDialog}
                                    onCancel={this.closeEditGroup}
                                    footer={null}
                                >
                                    <GroupEdit
                                        groupData={this.state.groupFormData}
                                        isCreateNew={this.state.isGroupDialogCreateNew}
                                        onCancel={this.closeEditGroup}
                                        onSave={this.props.onGroupSave}>
                                    </GroupEdit>
                                </Modal>
                                <Skeleton loading={!this.props.showDetail || isAdminGroupSelected}>
                                    {/* <Skeleton loading={!this.props.showDetail} active> */}
                                    <Spin spinning={isDetailLoading} size="large">
                                        <Transfer
                                            titles={[t('setup.userManagement.groupTitleTransferSource'), t('setup.userManagement.groupTitleTransferTarget')]}
                                            className="groupTransfer"
                                            dataSource={userList}
                                            rowKey={record => record.id}
                                            showSearch
                                            filterOption={this.filterOption}
                                            targetKeys={this.state.targetKeys}
                                            onChange={this.handleChange}
                                            onSearch={this.handleSearch}
                                            render={this.renderTransferItem}
                                            listStyle={{
                                                width: 300,
                                                height: 450,
                                                textAlign: "left"
                                            }}
                                            style={{ textAlign: "center" }}
                                        // operations={['add user to group', 'remove user from group']}
                                        />
                                    </Spin>
                                </Skeleton>
                            </Card>
                        </Col>
                        <Col span={12} className="colGroup">
                            <div className="groupRightsTable">
                            {groupRights}
                            </div>
                        </Col>
                    </Row>
                </Space>
                <Divider dashed ></Divider>
                <Row >
                    <NextSectionButton align="left" title={t('setup.userManagement.userSection')} toUrl="/setup/users"></NextSectionButton>
                    {/* <NextSectionButton align="right" title={t('setup.userManagement.domainSection')} toUrl="/setup/domain"></NextSectionButton> */}
                </Row>
            </div>
        );
    }
}

const GroupsWithRouter = withRouter(Groups);

export default withTranslation()(GroupsWithRouter);

GroupsWithRouter.propTypes = {
    onGroupSave: PropTypes.func.isRequired,
};