import { config } from "../../../config/Config";

/**
 * 
 * 
 * @param {*} translator 
 * @returns 
 */
export const getFroalaConfigurationByDescriptionLayout = ( translator) => {
    return {
        // remove powered by froala
        // charCounterMax: 250,
        attribution: false,
        key: 'bMA6aA6D6A2G3C-8TMIBDIa1NTMNZFFPFZc1d1Ib2a1E1fA4A3D3B3F2C6C4C4C3C1==',
        quickInsertButtons: [ 'image', 'video', 'table', 'ol', 'ul', 'hr'],
        paragraphFormat: {
            N: 'Normal',
            L: 'Align Left'
          },
        toolbarButtons: {
            'moreText': {
                'buttons': ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting']
            },
            'moreParagraph': {
                'buttons': ['formatOL', 'formatUL','align','paragraphFormat','paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote'],
                'buttonsVisible': 4
            },
            'moreRich': {
                'buttons': ['insertLink', 'insertImage', 'insertTable','insertVideo',  'emoticons', 'fontAwesome', 'specialCharacters', 'embedly', 'insertHR']
            },
            'moreMisc': {
                'buttons': ['undo', 'redo',   'spellChecker', 'selectAll', 'html', 'help'],
                'align': 'right',
                'buttonsVisible': 2
            }
        },
        toolbarButtonsMD: {
            'moreText': {
                'buttons': ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting']
            },
            'moreParagraph': {
                'buttons': ['formatOL', 'formatUL','align','paragraphFormat','paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote'],
                'buttonsVisible': 4
            },
            'moreRich': {
                'buttons': ['insertLink', 'insertImage', 'insertTable','insertVideo',  'emoticons', 'fontAwesome', 'specialCharacters', 'embedly', 'insertHR']
            },
            'moreMisc': {
                'buttons': ['undo', 'redo','spellChecker', 'selectAll', 'html', 'help'],
                'align': 'right',
                'buttonsVisible': 2
            }
        },
        toolbarButtonsSM:  {
            'moreText': {
                'buttons': ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting']
            },
            'moreParagraph': {
                'buttons': ['formatOL', 'formatUL','align','paragraphFormat','paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote'],
                'buttonsVisible': 4
            },
            'moreRich': {
                'buttons': ['insertLink', 'insertImage', 'insertTable','insertVideo',  'emoticons', 'fontAwesome', 'specialCharacters', 'embedly', 'insertHR']
            },
            'moreMisc': {
                'buttons': ['undo', 'redo',  'spellChecker', 'selectAll', 'html', 'help'],
                'align': 'right',
                'buttonsVisible': 2
            }
        },
        charCounterCount:false,
        imageUpload:false,
        imageUploadURL: config.url.FILES_URL + '/upload',
        imageAllowedTypes: ['jpeg', 'jpg', 'png',"svg+xml",'gif', 'webp'],
        // imageUploadParams: {
        //     editor_id: 'my_editor',
        //     entry_id: entryId
        // },
        // Set the file upload parameter.
        fileUploadParam: 'file',

        // Set the file upload URL.
        fileUploadURL: config.url.FILES_URL + '/upload',

        // Additional upload params.
        // fileUploadParams: {
        //     editor_id: 'my_editor',
        //     entry_id: entryId
        // },

        // Set request type.
        fileUploadMethod: 'POST',

        // Set max file size to 20MB.
        fileMaxSize: 20 * 1024 * 1024,

        // Allow to upload any file.
        fileAllowedTypes: ['*'],

        paragraphStyles: {
            froalaCustomInformation: translator('app.entry.edit.lblFroalaInfoBox'),
            froalaCustomTip: translator('app.entry.edit.lblFroalaTipBox'),
            froalaCustomNote: translator('app.entry.edit.lblFroalaNoteBox'),
            froalaCustomWarning: translator('app.entry.edit.lblFroalaWarningBox'),
        },
        toolbarSticky: true,
        toolbarStickyOffset: 0,
    };
};