import React from "react";
import { Link } from "react-router-dom";
import { Button, Input, Select, Form, Checkbox } from "antd";
import { SaveOutlined, CloseOutlined } from '@ant-design/icons';
import PropTypes from "prop-types";
import { baseEntryWithParentShape } from "../../shapes/BmcEntryShape";
import FolderProperties from '../../containers/detail/FolderProperties'
import { withTranslation } from 'react-i18next';


// Require Editor JS files.
import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/js/plugins.pkgd.min.js';
import '../../froala/plugins/drawio';
import '@fortawesome/fontawesome-free/js/all';

// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/plugins.pkgd.min.css';

// Require Font Awesome.
import 'font-awesome/css/font-awesome.css';

import { isEmptyValue, isUndefined, mergeDeep } from "../../utils/JsObjectHelper";
import { entryTypesRequestResult } from "../../shapes/RequestResult";
import { EMPTY_FOLDER_PROPERTIES_DEFINITION } from "../../utils/EmptyFolderPropertiesDefinition";

const Option = Select.Option;

class EntryNewForm extends React.Component {

    formRef = React.createRef();

    constructor(props) {
        super(props);

        let folderProps = EMPTY_FOLDER_PROPERTIES_DEFINITION;
        if (!isEmptyValue(this.props.generalSettingsObject) &&
            !isEmptyValue(this.props.generalSettingsObject.folderDefaults)) {
            folderProps = mergeDeep(EMPTY_FOLDER_PROPERTIES_DEFINITION, this.props.generalSettingsObject.folderDefaults);
        }
        this.state = {
            name: "",
            description: "",
            type: (
                props.folderCreation ? "folder" :
                    (this.props.parentData.properties.childObjectEntryTypes.length > 0 ? this.props.parentData.properties.childObjectEntryTypes[0] : "")
            ),
            properties: (props.folderCreation ? folderProps : {}),
            submitting: false,
            deleteEntryType: false,
            isTemplate: false
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.cancelFinalSubmit = this.cancelFinalSubmit.bind(this);
    }
    componentDidUpdate(prevProps) {
        if (this.props.entryTypesRequestResult !== prevProps.entryTypesRequestResult) {
            this.checkIfEntryTypeIsTemplate();
        }
    }


    checkIfEntryTypeIsTemplate = () => {
        const { entryTypesRequestResult, parentData, folderCreation } = this.props;
        let entryTypeList = entryTypesRequestResult.getData() || [];

        if (!folderCreation && parentData && parentData.properties && parentData.properties.childObjectEntryTypes) {
            entryTypeList = entryTypeList.filter(eT => parentData.properties.childObjectEntryTypes.includes(eT.type));
        }
        const currentType = this.state.type || (entryTypeList.length > 0 ? entryTypeList[0].type : null);

        const currentEntryType = entryTypeList.find(eT => eT.type === currentType);
        const isTemplate = !!currentEntryType && !!currentEntryType.description;

        this.setState({ isTemplate });
    }

    handleSubmit = (formValues) => {
        this.formRef.current.validateFields()
            .then(values => {
                this.setState({ submitting: true });
                let newEntry = {
                    name: null,
                    type: null,
                    description: null,
                    properties: {}
                };
                //if(this.props.folderCreation) values["type"] = "folder";
                newEntry.name = values["name"];
                newEntry.description = values["description"];
                newEntry.type = values["type"];

                newEntry.loadDescriptionFromDefinition = values["loadDescriptionFromDefinition"];


                if (this.props.folderCreation) {
                    newEntry.type = "folder";
                    if (!isUndefined(values.properties) && !isEmptyValue(values.properties)) {
                        newEntry.properties = values.properties;
                    } else {
                        newEntry.properties = this.state.properties;
                    }
                    newEntry.properties.workflowEvents = { onSave: "" };
                } else {
                    newEntry.properties = {};
                }
                this.props.onSaveNew(this.props.parentData.id, newEntry);
            })
            .catch(errorInfo => {
                console.log(errorInfo);
                return;
            });
    };

    cancelFinalSubmit = () => {
        this.setState({ submitting: false });
    };
    handleDeleteEntryType = (param) => {
        this.setState({ deleteEntryType: param })
    }
    changeEntryType = (value) => {
        const rr = this.props.entryTypesRequestResult;
        let entryTypeList = (!isUndefined(rr.getData()) && !isEmptyValue(rr.getData())) ? rr.getData() : [];
        let entryType = entryTypeList.length > 0 ? entryTypeList.find((el) => el.type === value) : null;
        if (entryType && entryType.description) {
            this.setState({ isTemplate: true });
        }
        else {
            this.setState({ isTemplate: false });
        }
    }


    render() {
        const { t } = this.props;

        let options = null;
        let checkbox = null;
        let search = <span />;
        /** @type {RequestResult} */
        const rr = this.props.entryTypesRequestResult;

        if (!this.props.folderCreation) {
            let entryTypeList = (!isUndefined(rr.getData()) && !isEmptyValue(rr.getData())) ? rr.getData() : [];
            //Filter entryTypes if parent folder is limited
            if (!this.props.folderCreation &&
                !isUndefined(this.props.parentData.properties) &&
                !isEmptyValue(this.props.parentData.properties) &&
                !isUndefined(this.props.parentData.properties.childObjectEntryTypes) &&
                this.props.parentData.properties.childObjectEntryTypes.length > 0) {
                entryTypeList = entryTypeList.filter(eT => { return this.props.parentData.properties.childObjectEntryTypes.indexOf(eT.type) > -1 });
            }
            options = entryTypeList.map(d => <Option value={d.type} key={d.name}>{d.name}</Option>);

            search = <Form.Item label={t('app.entry.new.lblType')} //validateStatus={typeError ? 'error' : ''} help={typeError || ''}
                name="type"
                rules={[{ required: true, message: t('app.entry.new.msgFillType') }]}
            >
                <Select
                    showSearch
                    style={{ width: 300 }}
                    placeholder={t('app.entry.new.lblTypeHint')}
                    optionFilterProp="value"
                    onChange={this.changeEntryType}
                    filterOption={(input, option) =>
                        option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    allowClear={true}
                >
                    {options}
                </Select>
            </Form.Item>;
            if (this.state.isTemplate) {
                checkbox = <Form.Item
                    name="loadDescriptionFromDefinition"
                    valuePropName="checked">
                    <Checkbox >{t('app.entry.new.checkboxUseTemplate')}</Checkbox>
                </Form.Item>
            }
        } else {
            search = <FolderProperties
                formRef={this.formRef}
                disabledBtn={this.handleDeleteEntryType}
            ></FolderProperties>;
        }

        return <Form
            onFinish={this.handleSubmit}
            ref={this.formRef}
            name="entry_new_form"
            layout="vertical"
            initialValues={this.state}
        >
            <Form.Item label={t('app.entry.new.lblName')} //validateStatus={nameError ? 'error' : ''} help={nameError || ''}
                name="name"
                rules={[{ required: true, message: t('app.entry.new.msgFillName') }]}
            >
                <Input placeholder={(this.props.folderCreation ? t('app.entry.new.lblNameHintFolder') : t('app.entry.new.lblNameHintObject'))} style={{ width: '100%' }} allowClear={true} />
            </Form.Item>
            {search}
            {checkbox}
            <Form.Item shouldUpdate>
                {() => {
                    return [
                        <Button type="primary" htmlType="submit" style={{ marginRight: '10px' }} icon={<SaveOutlined />}
                            key="saveBtn"
                            //disabled={hasErrors(getFieldsError())} 
                            disabled={
                                isUndefined(this.formRef.current) ||
                                (!isUndefined(this.formRef.current) &&
                                    (
                                        !this.formRef.current.isFieldsTouched(["name", "type"]) ||
                                        !!this.formRef.current.getFieldsError().filter(({ errors }) => errors.length).length
                                    )
                                ) || this.state.deleteEntryType
                            }
                            loading={this.state.submitting}
                        >
                            {t('app.entry.new.btnCreate')}
                        </Button>,
                        <Link to={`/entry/${this.props.parentData.id}`} key="closeBtn"><Button type="primary" disabled={this.state.deleteEntryType} icon={<CloseOutlined />}>{t('app.entry.new.btnClose')}</Button></Link>
                    ]
                }}
            </Form.Item>
        </Form>;
    }
}

export default withTranslation()(EntryNewForm);

EntryNewForm.propTypes = {
    parentData: baseEntryWithParentShape.isRequired,
    onSaveNew: PropTypes.func.isRequired,
    folderCreation: PropTypes.bool.isRequired,
    entryTypesRequestResult: entryTypesRequestResult.isRequired,
    generalSettingsObject: PropTypes.object
};