import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { bmcEntryShape, folderPropShape } from "../../../shapes/BmcEntryShape";
import { entryTypesRequestResult } from "../../../shapes/RequestResult";
import { isArray, isEmptyValue, isUndefined } from "../../../utils/JsObjectHelper";
import EntryChildTable from './EntryChildTable';
import { withTranslation } from 'react-i18next'
import { Card, Col, Row, Radio, Alert, Button, Space, Divider } from "antd";
import { fetchEntryRelOutgoingLineage } from '../../../apicalls/fetchEntryLineage'
import EntryTypeTag from "../../controls/EntryTypeTag";
import { SelectOutlined, TableOutlined, UndoOutlined } from "@ant-design/icons";
import Tree from "./tree/Tree";
import SearchTree from "./tree/SerchTree";
import { fetchRootHierarchy } from "../../../apicalls/fetchRootHierarchy";
import { isMobile, isTablet } from "../../../utils/AdaptiveLayout";
import OutsideTheHierarchy from "./tree/OutsideTheHierarchy";
const cloneDeep = require('lodash.clonedeep');

const TOO_MUCH_RECORDS_IN_SUBTREE = "@#CANNOTBEAR#@";

class EntryChildHierarchy extends React.Component {


    state = {
        treeData: [],
        fetchedData: {},
        selectedParent: null,
        hierarchyDefinition: null,
        loading: false,
        panelSize: "s",
        freeEntries: []
    };

    componentDidMount() {
        this.props.onMount();
    }

    componentDidUpdate(prevProps, prevState) {
        //Check if hierarchy definition is laoded and selected
        if (isEmptyValue(this.state.hierarchyDefinition)) {
            this.findHierarchyDefinition();
        }
    };

    handleSizeChange = (tgt) => {
        this.setState({ panelSize: tgt.target.value });
    };

    /**
     * Try to find hierarchy definition selected for folder.
     */
    findHierarchyDefinition = () => {
        if (!isEmptyValue(this.props.lineageSettingsList) &&
            !isEmptyValue(this.props.folderProperties) &&
            !isEmptyValue(this.props.folderProperties.childrenDisplayHierarchyDefId)) {
            let hierarchyDef = this.props.lineageSettingsList.find(hD => hD.name === this.props.folderProperties.childrenDisplayHierarchyDefId);
            if (!isEmptyValue(hierarchyDef) && !isEmptyValue(hierarchyDef.value.hierarchy)) {
                this.setState({ hierarchyDefinition: hierarchyDef.value.hierarchy }, this.prepareHierarchyRoot);
            }
        }
    };

    /**
     * Sets default hierarchy root (root layer for Tree) according to hierarchy definition.
     */
    getRootEntries = (rootEntriesArr, oldArr) => {
        let rootEntries = rootEntriesArr.map(e => { return { id: e.id, title: e.name, key: e.id, type: e.type }; });
        this.setState({ treeData: rootEntries });
        /**
        * Get not hierarchical
         */
        let etNameList = [];
        this.state.hierarchyDefinition.forEach((h) => etNameList.push(h.parent, h.child));
        let arr = [...new Set(etNameList)];
        arr = arr.filter(item => !oldArr.includes(item));
        fetchRootHierarchy(this.props.entryID, arr.join(','), (free) => {
            if (isArray(free)) {
                let freeEntries = free.map(e => { return { id: e.id, title: e.name, key: e.id, type: e.type }; });
                this.setState({ freeEntries: freeEntries });
            }
        });
    }
    prepareHierarchyRoot = () => {
        let parentNameList = [];
        //find ultimate parents in hierarchy
        parentNameList = this.state.hierarchyDefinition.map(h => h.parent);

        this.state.hierarchyDefinition.forEach(d => {
            parentNameList = parentNameList.filter(pN => (pN !== d.child || d.parent === d.child));
            // parentNameList = parentNameList.filter(pN => pN !== d.child );
        });

        let arr = [...new Set(parentNameList)];
        fetchRootHierarchy(this.props.entryID, arr.join(','), (root) => this.getRootEntries(root, arr));

        // let rootEntries = this.props.childsDataList.filter(ch => parentNameList.includes(ch.type))
        //     .sort((a, b) => a.name.localeCompare(b.name))
        //     .map(e => { return { id: e.id, title: e.name, key: e.id, type: e.type }; });

        // this.setState({ treeData: rootEntries });
    };
    /**
     * Handles TreeNodes request to load leaf data for node.
     * 
     * @param {*} treeNodeData 
     * @returns 
     */
    onLoadData = treeNodeData =>
        new Promise(resolve => {
            this.setState({ loading: true })
            if (treeNodeData.id === TOO_MUCH_RECORDS_IN_SUBTREE) {
                this.setState({ loading: false })
                resolve();
                return;
            }
            if (!(treeNodeData.key in this.state.fetchedData)) {
                fetchEntryRelOutgoingLineage(treeNodeData.id, (data) => { this.onRelationsLoaded(data, treeNodeData.key); resolve(); this.setState({ loading: false }) });
            } else {
                if (treeNodeData.children) {
                    this.setState({ loading: false })
                    resolve();
                    return;
                }
                this.setState({ loading: false })
                resolve();
            }
            return;
        });

    /**
     * Sets selected id which results in filtering table. 
     * If there are no loaded relation data for this id. Loads them.
     * 
     * @param {string} selectedItemId 
     * @param {string} selectedItemKey 
     */
    onTreeOpenTable = (selectedItemId, selectedItemKey) => {
        if (!(selectedItemKey in this.state.fetchedData)) {
            fetchEntryRelOutgoingLineage(selectedItemId, (data) => { this.onRelationsLoaded(data, selectedItemKey) });
        }
        this.setState({ selectedParent: selectedItemKey });
    };

    /**
     * Handle relations loaded. When there are some new relations loaded, ad them into Tree hierarchy data object.
     * 
     * @param {*} data 
     * @param {*} parentId 
     */
    onRelationsLoaded = (data, parentId) => {

        let entryLimit = (!isEmptyValue(this.props.generalSettingsObject) ?
            //if settings are loaded, get value of the limit
            this.props.generalSettingsObject.limits.hierarchySubTreeLimit :
            //100 if waiting to settings
            100);

        if (!isUndefined(data)) {
            let entryId = this.getItemIdFromIDPath(parentId);
            let parentType = this.props.childsDataList.find(e => e.id === entryId).type;
            let childsTreeArr = [];
            let childsArr = [];

            if (data.length > entryLimit) {
                //data = data.slice(0, 31);
                //There is too much items in subtree. Display warning instead
                // childsTreeArr.push({ id: TOO_MUCH_RECORDS_IN_SUBTREE, title: TOO_MUCH_RECORDS_IN_SUBTREE, key: TOO_MUCH_RECORDS_IN_SUBTREE, type: TOO_MUCH_RECORDS_IN_SUBTREE, relType: TOO_MUCH_RECORDS_IN_SUBTREE });
                //insert only to parent map
                // data.forEach(d => {
                //     if (isEmptyValue(d.deleted)) {
                //         if (!isUndefined(this.state.hierarchyDefinition.find(h => h.parent === parentType && h.child === d.target.type && h.relation === d.name))) {
                //             childsArr.push(d.target.id);
                //         }
                //     }
                // });
            }
            //Items can be displayed in hierarchy
            data.forEach(d => {
                if (isEmptyValue(d.deleted)) {
                    if (!isUndefined(this.state.hierarchyDefinition.find(h => h.parent === parentType && h.child === d.target.type && h.relation === d.name))) {
                        childsArr.push(d.target.id);
                        if (this.props.childsDataList.find(e => e.id === d.target.id)) {
                            childsTreeArr.push({ id: d.target.id, title: d.target.name, key: d.target.id, type: d.target.type, relType: d.name });
                        }
                    }
                }
            });
            let newData = cloneDeep(this.state.fetchedData);
            newData[parentId] = childsArr;

            let newTreeData = cloneDeep(this.state.treeData);
            childsTreeArr = childsTreeArr.sort((a, b) => a.title.localeCompare(b.title));
            this.insertTreeChildren(newTreeData, parentId, childsTreeArr);

            this.setState({ fetchedData: newData, treeData: newTreeData });
        }
    };

    /**
     * Ads TreeNode data into Tree data object which will rerender Tree.
     * 
     * @param {*} treeData 
     * @param {*} parentId 
     * @param {*} childData 
     * @returns 
     */
    insertTreeChildren = (treeData, parentId, childData) => {
        for (let i = 0; i < treeData.length; i++) {
            if (treeData[i].key === parentId) {
                childData.forEach(element => {
                    element.key = parentId + '#|#' + element.relType + '#|#' + element.key;
                });
                treeData[i].children = childData;
                return;
            } else if (treeData[i].children) {
                this.insertTreeChildren(treeData[i].children, parentId, childData);
            }
        }
    };

    /**
     * AntDesign TreeNode title renderer. Combines action button entry type tag and entry name.
     * 
     * @param {*} data 
     * @returns 
     */
    renderTreeNodeTitle = (data) => {
        // console.log(data);
        const { t } = this.props;

        if (data.id === TOO_MUCH_RECORDS_IN_SUBTREE && data.title === TOO_MUCH_RECORDS_IN_SUBTREE) {
            return <Alert message={t('app.entry.view.childHierarchyTooMuchToBear')} type="warning" showIcon />;
        } else {
            let entryTypeNameList = [];
            if (this.props.entryTypesRequestResult.getState().isDone()) {
                entryTypeNameList = this.props.entryTypesRequestResult.getData().map((item, key) => { return { type: item.type, name: item.name, color: item.properties.typeColor }; });
            }
            // style={{overflow:'hidden',textOverflow:"ellipsis",whiteSpace:"nowrap"}}
            return <div style={{ display: 'flex', flexDirection: 'row', whiteSpace: "nowrap" }}>
                <Button type="dashed" shape="circle" icon={<TableOutlined />} onClick={() => this.onTreeOpenTable(data.id, data.key)} size="small" />
                <EntryTypeTag entryTypeName={data.type} entryTypeNameList={entryTypeNameList}></EntryTypeTag>

                <Link className="linkEntryChildHierarchy" to={`/entry/${data.id}`} target="_blank" title={data.title}>{data.title}</Link>
            </div>
            // <Space style={{overflow:'hidden',textOverflow:"ellipsis",whiteSpace:"nowrap"}} >
            //    
            // </Space>;
        }
    };

    /**
     * Helper for getting leaf id from hierarchy id path
     * 
     * @param {string} idPath 
     * @returns leaf id in id path
     */
    getItemIdFromIDPath = (idPath) => {
        return idPath.split("#|#")[idPath.split("#|#").length - 1];
    };

    resetTable = () => {
        this.setState({ selectedParent: null });
    };
    renderTreeNodes = (data) => {
        return data.map(item => {
            if (item.children) {
                if (item.children && item.children.length >= 30) {
                    return (
                        <Tree
                            key={item.key}
                            selectedItemId={item.id}
                            onLoadData={this.onLoadData}
                            selectedItemKey={item.key}
                            title={this.renderTreeNodeTitle(item)}
                            loading={this.state.loading} >
                            <SearchTree
                                data={item.children}
                                renderTreeNodes={this.renderTreeNodes} />
                        </Tree>
                    )
                }
                else {
                    return (
                        <Tree
                            key={item.key}
                            selectedItemId={item.id}
                            selectedItemKey={item.key}
                            onLoadData={this.onLoadData}
                            title={this.renderTreeNodeTitle(item)}
                            loading={this.state.loading}
                        >
                            {this.renderTreeNodes(item.children)}
                        </Tree>
                    )
                }
            }
            return <Tree
                key={item.key}
                selectedItemId={item.id}
                onLoadData={this.onLoadData}
                selectedItemKey={item.key}
                title={this.renderTreeNodeTitle(item)}
                loading={this.state.loading} />
        })
    }

    render() {
        const { t } = this.props;
        let tableData = []
        let parentName = t('app.entry.view.tblEntriesTitleParent');

        if (!isEmptyValue(this.state.selectedParent)) {
            if (this.state.selectedParent in this.state.fetchedData) {
                let dispalyIds = this.state.fetchedData[this.state.selectedParent];
                tableData = this.props.childsDataList.filter(d => dispalyIds.includes(d.id));
            }
            let selParId = this.getItemIdFromIDPath(this.state.selectedParent);
            parentName = <Space>
                <Button icon={<UndoOutlined />} onClick={this.resetTable}>{t('app.entry.view.btnResetChildTable')}</Button>
                <Link to={`/entry/${selParId}`} target="_blank" ><SelectOutlined /> {this.props.childsDataList.find(d => d.id === selParId).name}</Link>
            </Space>;
        } else {
            tableData = this.props.childsDataList;
        }

        let hierarchyPanel = <Radio.Group value={this.state.panelSize} onChange={this.handleSizeChange} size="small">
            <Radio.Button value="s" size="small">S</Radio.Button>
            <Radio.Button value="m" size="small">M</Radio.Button>
            <Radio.Button value="l" size="small">L</Radio.Button>
        </Radio.Group>;

        let gridSpan = 6;
        switch (this.state.panelSize) {
            case "s":
                gridSpan = 6;
                break;
            case "m":
                gridSpan = 8;
                break;
            case "l":
                gridSpan = 10;
                break;
            default:
                break;
        }

        let infoBox = null;
        if (this.props.entryTypesRequestResult.getState().isDone() && this.state.treeData.length <= 0) {
            infoBox = <Alert message={t('app.entry.view.childHierarchyEmpty')} type="info" showIcon />;
        }
        let freeEntries = null;
        if (this.state.freeEntries.length > 0) {
            infoBox = <Alert message={t('app.entry.view.childHierarchyAreNotHierarchical')} style={{ marginBottom: '10px' }} type="info" showIcon />;
            let dataFree = this.state.freeEntries.sort((a, b) => {
                if (a.title.toLowerCase() < b.title.toLowerCase()) {
                    return -1;
                } else if (a.title.toLowerCase() > b.title.toLowerCase()) {
                    return 1;
                } else {
                    return 0;
                }
            });
            freeEntries = <> <Divider orientation="left" >{isTablet() || isMobile() ? t('app.entry.view.childHierarchyAreNotHierarchicalDividerMobile') : t('app.entry.view.childHierarchyAreNotHierarchicalDividerText')}</Divider>
                <OutsideTheHierarchy
                    treeData={dataFree}
                    entryTypesRequestResult={this.props.entryTypesRequestResult}
                    childsDataList={this.props.childsDataList}
                    lineageSettingsList={this.props.lineageSettingsList}
                    folderProperties={this.props.folderProperties}
                    entryID={this.props.entryID} 
                    onTreeOpenTable={this.onTreeOpenTable} 
                /></>;
        }
        let tableDiv = document.querySelector('.EntryChildTable');
        let heightTable = null
        if (tableDiv) {
            heightTable = tableDiv.offsetHeight < 1060 ? 1060 : tableDiv.offsetHeight;
        }
        let dataTree = this.state.treeData.sort((a, b) => {
            if (a.title.toLowerCase() < b.title.toLowerCase()) {
                return -1;
            } else if (a.title.toLowerCase() > b.title.toLowerCase()) {
                return 1;
            } else {
                return 0;
            }
        });
        return <Card title={this.props.cardTitle}>
            <Row gutter={6} >
                <Col span={isTablet()||isMobile()?24: gridSpan} >
                    <Card size="small" extra={hierarchyPanel} title={t('app.entry.view.childHierarchyTitle')} style={{ maxHeight: heightTable, overflow: 'auto' }} className='EntryChildHierarchy' loading={!this.props.entryTypesRequestResult.getState().isDone()}>
                        {infoBox}
                        {this.renderTreeNodes(dataTree)}
                        {freeEntries}
                        {/* <Tree showLine showIcon={false} 
                                    selectable={false}
                                    loadData={this.onLoadData}
                                    style={{overflow:'hidden'}}
                                    treeData={this.state.treeData}
                                    titleRender={this.renderTreeNodeTitle}
                                    >
                                </Tree> */}
                    </Card>
                </Col>
                <Col span={isTablet()||isMobile()?24:24 - gridSpan} >
                    <EntryChildTable
                        childsDataList={tableData}
                        folderProperties={this.props.folderProperties}
                        entryTypesRequestResult={this.props.entryTypesRequestResult}
                        tableTitle={<div className="childTableTitle">{parentName}</div>}
                    ></EntryChildTable>
                </Col>
            </Row>
        </Card>;
    }
}

export default withTranslation()(EntryChildHierarchy);

EntryChildHierarchy.propTypes = {
    childsDataList: PropTypes.arrayOf(bmcEntryShape).isRequired,
    folderProperties: folderPropShape.isRequired,
    entryTypesRequestResult: entryTypesRequestResult.isRequired
};