import { Link } from "react-router-dom";
import React from "react";
import { Breadcrumb, Menu, Dropdown, Space } from "antd";
import { DownOutlined, FolderOpenOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import PropTypes from "prop-types";
import { idNameTypeWithFoldersShape } from "../shapes/IdNameShapes";
import { isEmptyValue, isUndefined } from "../utils/JsObjectHelper";
import { isMobile } from "../utils/AdaptiveLayout";


const itemRender = (route, params, routes, paths) => {
    const last = routes.indexOf(route) === routes.length - 1;
    const hasNoPath = isEmptyValue(route.path);
    return last || hasNoPath ? (
        <span>{route.breadcrumbName}</span>
    ) : (
        <Link to={route.path}>{route.breadcrumbName}</Link>
    );
};

class EntryDetailBreadCrumb extends React.Component {

    render() {
        const routes = [];
        if (!this.props.entries && !this.props.entries.length < 1) return <p>&nbsp;</p>;
        this.props.entries.forEach((entry) => {
            if (entry.hasOwnProperty("subFolders") && !isUndefined(entry.subFolders) && entry.subFolders.length > 0) {
                let items = [];
                let menu = (<Menu className="scrollable" style={{ maxHeight: '60vh', overflowY: 'auto' }}>
                    {items}
                </Menu>);
                for (const folder of entry.subFolders) {
                    items.push(<Menu.Item key={folder.id}><a href={`/entry/${folder.id}`}>{folder.name}</a></Menu.Item>)
                }
                routes.push(<Breadcrumb.Item overlay={menu}><a href={`/entry/${entry.id}`}> {entry.name}</a> </Breadcrumb.Item>);
            }
            else {
                routes.push(<Breadcrumb.Item><a href={`/entry/${entry.id}`}>{entry.name}</a> </Breadcrumb.Item>);
            }
            if (entry.gapMarker) {
                routes.push(<Breadcrumb.Item>...</Breadcrumb.Item>);
            }
        });
        let main = <Breadcrumb> {routes}</Breadcrumb>;
        if (isMobile()) {
            let listEntries = this.props.entries.filter((el) => el.systemType === "folder")
            if (listEntries.length > 1) {
                let items = listEntries.map((entry) => ({
                    label: <Link to={`/entry/${entry.id}`}>{entry.name}</Link>,
                    key: entry.id
                }));
                //<ArrowLeftOutlined  onClick={() => window.history.back()} style={{fontSize:'16px',marginRight:'8px'}}/>
                main = <div style={{ paddingBottom: "4px", }}>
                    <Dropdown overlay={<Menu items={items} />} trigger={['click']} >
                        <a onClick={(e) => e.preventDefault()} style={{ fontSize: '18px', color: 'black', }} >
                            <FolderOpenOutlined /> <span style={{ display: "inline-flex", maxWidth: '80%', overflow: 'hidden', whiteSpace: 'nowrap' }}>{listEntries[listEntries.length - 1].name}</span> <DownOutlined />
                        </a>
                    </Dropdown></div>;
            } else {
                main = null;
            }
        }
        return main;
    }
}

EntryDetailBreadCrumb.propTypes = {
    entries: PropTypes.arrayOf(idNameTypeWithFoldersShape).isRequired
};

export default EntryDetailBreadCrumb;

